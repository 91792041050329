import {
    SET_DEPOT_ACCESS_POINTS,
    SET_DEPOT_SWITCHES,
    SET_DEPOT_CONTROLLERS
} from '../actions';

import { groupByStores, getCoreSwitches, getPSFSwicthes } from '../utils/utilities';
import { DeviceStatus } from '../utils/constants';

const groupByName = 'site';
const initialState = {
    isLoading: false,
    depots: {
        apOnline: 0,
        apOffline: 0,
        assignedApOffline: 0,
        failedApsGroupByDepots: [],
        assignedFailedApsGroupByDepots: [],
        switchesOnline: 0,
        switchesOffline: 0,
        failedSwitchesGroupByDepots: [],
        coreSwitchesOffline: 0,
        failedCoreSwitchesGroupByDepots: [],
        pfsSwitchesOnline: 0,
        pfsSwitchesOffline: 0,
        failedPfsSwitchesGroupByDepots: [],
        controllersOnlineCount: 0,
        controllersOfflineCount: 0,
        offlineControllersGroupByDepots: []
    },
    accessPoints: [],
    switches: []
}

const depotsReducer = (state = initialState, action) => {
    // if (action.type === SET_DEPOT_ACCESS_POINTS) {
    //     let aps = action.depotAccessPoints;
    //     let offlineAps = aps.filter(ap => ap.status === DeviceStatus.Down);
    //     return {
    //         ...state,
    //         accessPoints: aps,
    //         depots: {
    //             ...state.depots,
    //             apOnline: aps.filter(ap => ap.status === DeviceStatus.Up).length,
    //             apOffline: offlineAps.length,
    //             failedApsGroupByDepots: groupByStores(offlineAps, groupByName)
    //         }
    //     }
    // }

    // if (action.type === SET_DEPOT_SWITCHES) {
    //     let switches = action.depotSwitches;
    //     let offlineSwitches = switches.filter(s => s.status === DeviceStatus.Down);
    //     let onlineSwitches = switches.filter(s => s.status === DeviceStatus.Up);
    //     return {
    //         ...state,
    //         switches: switches,
    //         depots: {
    //             ...state.depots,
    //             switchesOnline: onlineSwitches.length,
    //             switchesOffline: offlineSwitches.length,
    //             failedSwitchesGroupByDepots: groupByStores(offlineSwitches, groupByName)
    //         }
    //     }
    // }

    if (action.type === SET_DEPOT_ACCESS_POINTS) {
        let apsData = action.depotAccessPoints;
        let failedAps = apsData.failedDepotsAccessPoints;
        let assignedAps = failedAps.filter(s => Boolean(s.notes));
        let offlineAps = failedAps.filter(s => !Boolean(s.notes));
        offlineAps = offlineAps.filter(f => f.site && !f.site.includes('Pre Staging not in Live'));
        return {
            ...state,
            depots: {
                ...state.depots,
                apOnline: apsData.onlineCount,
                apOffline: offlineAps.length,
                assignedApOffline: assignedAps.length,
                assignedFailedApsGroupByDepots: groupByStores(assignedAps, groupByName),
                failedApsGroupByStores: groupByStores(offlineAps, groupByName)
            }
        }
    }

    if (action.type === SET_DEPOT_SWITCHES) {
        let switchesData = action.depotSwitches;
        let failedDepotsSwitches = switchesData.failedDepotsSwitches;
        //failedDepotsSwitches = failedDepotsSwitches.filter(f => f.site && !f.site.includes('Pre Staging not in Live'));
        let coreSwitches = getCoreSwitches(failedDepotsSwitches);
        let pfsSwitches = getPSFSwicthes(failedDepotsSwitches);
        return {
            ...state,
            depots: {
                ...state.depots,
                switchesOnline: switchesData.onlineCount,
                switchesOffline: switchesData.offlineCount,
                failedSwitchesGroupByDepots: groupByStores(failedDepotsSwitches, groupByName),
                coreSwitchesOnline: (switchesData.depotCoreSwicthesTotal - coreSwitches.length),
                coreSwitchesOffline: coreSwitches.length,
                failedCoreSwitchesGroupByDepots: groupByStores(coreSwitches, groupByName),
                pfsSwitchesOnline: (switchesData.depotPfsSwitchesTotal - pfsSwitches.length),
                pfsSwitchesOffline: pfsSwitches.length,
                failedPfsSwitchesGroupByDepots: groupByStores(pfsSwitches, groupByName),
            }
        }
    }

    if (action.type === SET_DEPOT_CONTROLLERS) {
        return {
            ...state,
            depots: {
                ...state.depots,
                controllersOnlineCount: action.depotControllers.onlineCount,
                controllersOfflineCount: action.depotControllers.offlineCount,
                offlineControllersGroupByDepots: groupByStores(action.depotControllers.offlineDepotControllers, 'siteName')
            }
        }
    }
    return state;
}


export default depotsReducer;

