import React, { useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import DevicesModal from '../Modals/DevicesModal';
import { getDeviceName } from '../../utils/utilities';
import './Card.css';
import { LocationType } from '../../utils/constants';

const FailedCountCard = (props) => {

    let defaultCount = 0;
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const { deviceType, failedDevices, locationType, failedCount } = props;

    const renderTooltip = (props) => {
        const deviceName = getDeviceName(deviceType);
        return (
            <Tooltip id="button-tooltip" {...props}>
                Click here to view the failed {deviceName}
            </Tooltip>
        );
    }

    return (
        <div>
            {
                failedCount > 0
                    ? locationType === LocationType.All
                        ? <div className='count-card failed-count' style={{ cursor: 'default' }}>{failedCount}</div>
                        : <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
                            <div className='count-card failed-count' onClick={() => handleShow()}>{failedCount}</div>
                        </OverlayTrigger>
                    : <div className='count-card default-count'>{defaultCount}</div>
            }
            <DevicesModal show={show} handleClose={handleClose} failedDevices={failedDevices || 0} deviceType={deviceType} locationType={locationType} failedDevicesCount={failedCount} />
        </div>
    );
}

export default FailedCountCard;