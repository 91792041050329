import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import store from '../../store';
import NetworkCategoryList from './NetworkCategoryList';
import distributionCentresApi from '../../api/distributionCentresApi';
import './Settings.css';
import { searchDistributionCentres } from '../../actions';

const DistributionCentreList = (props) => {

    const { isLoadingDistributionCentres, distributionCentres } = useSelector((state) => state.distributionCentresReducer);

    useEffect(() => {
        distributionCentresApi.getDistributionCentres();
    }, []);

    const onAddDistributionCentreItem = (categoryItem) => {
        distributionCentresApi.addDistributionCentreItem(categoryItem);
    }

    const onEditDistributionCentreItem = (categoryItem) => {
        distributionCentresApi.updateDistributionCentreItem(categoryItem);
    }

    const onDeleteDistributionCentreItem = (categoryItemId) => {
        distributionCentresApi.deleteDistributionCentreItem(categoryItemId);
    }

    const onSearchDistributionCentreList = (searchKey) => {
        store.dispatch(searchDistributionCentres(searchKey));
    }

    return (
        <NetworkCategoryList isLoadingItems={isLoadingDistributionCentres}
            categoryList={distributionCentres}
            categoryName='Distribution Centre'
            showSearchBox={true}
            onAddCategoryItem={onAddDistributionCentreItem}
            onEditCategoryItem={onEditDistributionCentreItem}
            onDeleteCategoryItem={onDeleteDistributionCentreItem}
            onSearchCategoryList={onSearchDistributionCentreList} />
    );
}

export default DistributionCentreList;