import React from 'react';
import { useSelector } from 'react-redux';
import DeviceStatusCard from '../Dashboard/DeviceStatusCard';
import DashboardRowHeader from '../Dashboard/DashboardRowHeader';
import { DeviceType, LocationType } from '../../utils/constants';
import SupportCenterIcon from '../../assets/icons/SupportCenter.svg';

const SupportCenterSummary = () => {

    const { isLoading } = useSelector((state) => state.storesReducer);
    const { supportCenters } = useSelector((state) => state.supportCentersReducer);
    const supportCentersHeader = { icon: SupportCenterIcon, title: 'Support Center' };

    return (
        <div className='row-item'>
            <div className='first-column'>
                <DashboardRowHeader header={supportCentersHeader} />
            </div>
            <DeviceStatusCard isLoading={isLoading} onlineDevicesCount={supportCenters.coreSwitchesOnline} offlineDevicesCount={supportCenters.coreSwitchesOffline || 0} failedDevices={supportCenters.failedCoreSwitchesGroupByStores} deviceType={DeviceType.CoreSwitch} locationType={LocationType.SSC} />
            <DeviceStatusCard isLoading={isLoading} onlineDevicesCount={supportCenters.switchesOnline} offlineDevicesCount={supportCenters.switchesOffline} failedDevices={supportCenters.failedSwitchesGroupByStores} deviceType={DeviceType.Switch} locationType={LocationType.SSC} />
            <DeviceStatusCard isLoading={isLoading} onlineDevicesCount={supportCenters.pfsSwitchesOnline || 0} offlineDevicesCount={supportCenters.pfsSwitchesOffline || 0} failedDevices={supportCenters.failedPfsSwitchesGroupByStores} deviceType={DeviceType.PfsSwitch} locationType={LocationType.SSC} />
            <DeviceStatusCard isLoading={isLoading} onlineDevicesCount={supportCenters.apOnline} offlineDevicesCount={supportCenters.apOffline} assignedOfflineDevicesCount={supportCenters.assignedApOffline} failedDevices={supportCenters.failedApsGroupByStores} assignedFailedDevices={supportCenters.assignedFailedApsGroupByStores} deviceType={DeviceType.AccessPoint} locationType={LocationType.SSC} />
            <DeviceStatusCard isLoading={isLoading} onlineDevicesCount={supportCenters.controllersOnlineCount || 0} offlineDevicesCount={supportCenters.controllersOfflineCount || 0} failedDevices={supportCenters.offlineControllersGroupByStores} deviceType={DeviceType.Controllers} locationType={LocationType.SSC} />
            <DeviceStatusCard isLoading={isLoading} onlineDevicesCount={supportCenters.wanOnline || 0} offlineDevicesCount={supportCenters.wanOffline || 0} failedDevices={[]} deviceType={DeviceType.Unknown} locationType={LocationType.SSC} />


        </div>
    );
}

export default SupportCenterSummary;