
import { postAsync, getAsync, putAsync, deleteAsync } from "./apiBase";
import store from '../store';
import { setIsDeletingCategory, setIsSavingCategory, setCategoryApiResponse, setIsLoadingDistributionCentres, setDistributionCentres, } from "../actions";

const getDistributionCentres = async () => {
    store.dispatch(setIsLoadingDistributionCentres(true));
    const distributionCentres = await getAsync('distributioncentre/getdistributioncentres');
    store.dispatch(setDistributionCentres(distributionCentres))
}

const addDistributionCentreItem = async (distributionCentreItem) => {
    store.dispatch(setIsSavingCategory(true));
    var response = await postAsync('distributioncentre/adddistributioncentre', distributionCentreItem);
    store.dispatch(setCategoryApiResponse(response.data));
    store.dispatch(setIsSavingCategory(false));
    await getDistributionCentres();
}

const updateDistributionCentreItem = async (distributionCentreItem) => {
    store.dispatch(setIsSavingCategory(true));
    var response = await putAsync('distributioncentre/updatedistributioncentre', distributionCentreItem);
    store.dispatch(setCategoryApiResponse(response.data));
    store.dispatch(setIsSavingCategory(false));
    await getDistributionCentres();
}

const deleteDistributionCentreItem = async (distributionCentreId) => {
    store.dispatch(setIsDeletingCategory(true));
    var response = await deleteAsync('distributioncentre/deletedistributioncentre/' + distributionCentreId);
    store.dispatch(setCategoryApiResponse(response.data));
    store.dispatch(setIsDeletingCategory(false));
    await getDistributionCentres();
}

export default { getDistributionCentres, addDistributionCentreItem, updateDistributionCentreItem, deleteDistributionCentreItem };