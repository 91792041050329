
import { postAsync, getAsync, putAsync, deleteAsync } from "./apiBase";
import store from '../store';
import { setIsDeletingCategory, setIsSavingCategory, setCategoryApiResponse, setIsLoadingDepots, setDepots } from "../actions";

const getDepots = async () => {
    store.dispatch(setIsLoadingDepots(true));
    const depotList = await getAsync('depots/getdepots');
    store.dispatch(setDepots(depotList))
}

const addDepot = async (depot) => {
    store.dispatch(setIsSavingCategory(true));
    var response = await postAsync('depots/adddepot', depot);
    store.dispatch(setCategoryApiResponse(response.data));
    store.dispatch(setIsSavingCategory(false));
    await getDepots();
}

const updateDepot = async (depot) => {
    store.dispatch(setIsSavingCategory(true));
    var response = await putAsync('depots/updatedepot', depot);
    store.dispatch(setCategoryApiResponse(response.data));
    store.dispatch(setIsSavingCategory(false));
    await getDepots();
}

const deleteDepot = async (depotId) => {
    store.dispatch(setIsDeletingCategory(true));
    var response = await deleteAsync('depots/deletedepot/' + depotId);
    store.dispatch(setCategoryApiResponse(response.data));
    store.dispatch(setIsDeletingCategory(false));
    await getDepots();
}

export default { getDepots, addDepot, updateDepot, deleteDepot };