import React from 'react';

import './Dashboard.css';

import OverAllIcon from '../../assets/icons/OverAll.svg';

const DashboardRowHeader = (props) => {
    const { icon, title } = props.header;

    return (
        <div className='row-header'>
            <img src={icon} width={35} />
            <div className='row-title'> {title}</div>
        </div>
    );
}

export default DashboardRowHeader;