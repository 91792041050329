import React from 'react';

const TruckIcon = (props) => {

    return (
        <svg width={props.width || 24} height={props.height || 24} viewBox="0 0 24 24">
            <title>icon/delivery_24px</title>
            <desc>Created with Sketch.</desc>
            <defs></defs>
            <g id="icon/delivery_24px" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <rect id="holder" x="0" y="0" width="24" height="24"></rect>
                <g id="ic_delivery" transform="translate(1.000000, 2.000000)" fill={props.fill}>
                    <path d="M2,10.7333333 L6.16858238,6.35632184 C6.54946841,5.9563915 6.53402995,5.32341477 6.13409962,4.94252874 C5.73416928,4.5616427 5.10119255,4.57708116 4.72030651,4.97701149 L0,9.93333333 L0,12.7777778 C1.77635684e-15,14.557362 1.44263803,16 3.22222222,16 L18.7777778,16 C20.557362,16 22,14.557362 22,12.7777778 L22,3.22222222 C22,1.44263803 20.557362,-2.22044605e-16 18.7777778,0 L11,4.4408921e-16 C9.22041581,7.77156117e-16 7.77777778,1.44263803 7.77777778,3.22222222 L7.77777778,10.3333333 C7.77777778,10.8856181 8.22549303,11.3333333 8.77777778,11.3333333 C9.33006253,11.3333333 9.77777778,10.8856181 9.77777778,10.3333333 L9.77777778,3.22222222 C9.77777778,2.54720753 10.3249853,2 11,2 L18.7777778,2 C19.4527925,2 20,2.54720753 20,3.22222222 L20,12.7777778 C20,13.4527925 19.4527925,14 18.7777778,14 L3.22222222,14 C2.54720753,14 2,13.4527925 2,12.7777778 L2,10.7333333 Z" id="shape" fill-rule="nonzero" transform="translate(11.000000, 8.000000) scale(-1, 1) translate(-11.000000, -8.000000) "></path>
                    <circle id="shape" cx="17" cy="19" r="2"></circle>
                    <circle id="shape" cx="5" cy="19" r="2"></circle>
                </g>
            </g>
        </svg>
    );
}

export default TruckIcon;