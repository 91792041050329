import React from 'react';

import './Card.css';

const TimeIntervalCard = (props) => {
    const { name, selected, value, onSelect } = props;
    return (
        <div className={`time-interval-item ${selected ? 'time-interval-item-selected' : ''}`} onClick={() => onSelect(value)}>
            {name}
        </div>
    );
}

export default TimeIntervalCard;