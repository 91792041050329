
import { postAsync, getAsync, putAsync, deleteAsync } from "./apiBase";
import store from '../store';
import { setIsDeletingCategory, setIsSavingCategory, setCategoryApiResponse, setIsLoadingContactCentreList, setContactCentreList } from "../actions";

const getContactCentres = async () => {
    store.dispatch(setIsLoadingContactCentreList(true));
    const ccList = await getAsync('contactcentre/getcontactcentres');
    store.dispatch(setContactCentreList(ccList))
}

const addContactCentreItem = async (ccItem) => {
    store.dispatch(setIsSavingCategory(true));
    var response = await postAsync('contactcentre/addcontactcentreitem', ccItem);
    store.dispatch(setCategoryApiResponse(response.data));
    store.dispatch(setIsSavingCategory(false));
    await getContactCentres();
}

const updateContactCentreItem = async (ccItem) => {
    store.dispatch(setIsSavingCategory(true));
    var response = await putAsync('contactcentre/updatecontactcentreitem', ccItem);
    store.dispatch(setCategoryApiResponse(response.data));
    store.dispatch(setIsSavingCategory(false));
    await getContactCentres();
}

const deleteContactCentreItem = async (contactCentreId) => {
    store.dispatch(setIsDeletingCategory(true));
    var response = await deleteAsync('contactcentre/deletecontactcentreitem/' + contactCentreId);
    store.dispatch(setCategoryApiResponse(response.data));
    store.dispatch(setIsDeletingCategory(false));
    await getContactCentres();
}

export default { getContactCentres, addContactCentreItem, updateContactCentreItem, deleteContactCentreItem };