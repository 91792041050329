import { SET_SUPPORT_CENTER_SWITCHES, SET_SUPPORT_CENTER_ACCESS_POINTS, SET_SUPPORT_CENTER_CONTROLLERS } from "../actions";
import { groupByStores, getCoreSwitches, getPSFSwicthes } from '../utils/utilities';

const groupByName = 'site';
const initialState = {
    supportCenters: {
        apOnline: 0,
        apOffline: 0,
        assignedApOffline: 0,
        failedApsGroupByStores: [],
        assignedFailedApsGroupByStores: [],
        switchesOnline: 0,
        switchesOffline: 0,
        failedSwitchesGroupByStores: [],
        coreSwitchesOnline: 0,
        coreSwitchesOffline: 0,
        failedCoreSwitchesGroupByStores: [],
        pfsSwitchesOnline: 0,
        pfsSwitchesOffline: 0,
        failedPfsSwitchesGroupByStores: [],
        controllersOnlineCount: 0,
        controllersOfflineCount: 0,
        offlineControllersGroupByStores: []
    },
    accessPoints: [],
    switches: []
}

const supportCentersReducer = (state = initialState, action) => {

    if (action.type === SET_SUPPORT_CENTER_ACCESS_POINTS) {
        let apsData = action.sscAccessPoints;
        let failedAps = apsData.failedSSCAccessPoints;
        let assignedAps = failedAps.filter(s => Boolean(s.notes));
        let offlineAps = failedAps.filter(s => !Boolean(s.notes));
        offlineAps = offlineAps.filter(f => f.site && !f.site.includes('Pre Staging not in Live'));
        return {
            ...state,
            supportCenters: {
                ...state.supportCenters,
                apOnline: apsData.onlineCount,
                apOffline: offlineAps.length,
                assignedApOffline: assignedAps.length,
                assignedFailedApsGroupByStores: groupByStores(assignedAps, groupByName),
                failedApsGroupByStores: groupByStores(offlineAps, groupByName)
            }
        }
    }

    if (action.type === SET_SUPPORT_CENTER_SWITCHES) {
        let switchesData = action.sscSwitches;
        let failedSupportCenterSwitches = switchesData.failedSupportCenterSwitches;
        let coreSwitches = getCoreSwitches(failedSupportCenterSwitches);
        let pfsSwitches = getPSFSwicthes(failedSupportCenterSwitches);
        return {
            ...state,
            supportCenters: {
                ...state.supportCenters,
                switchesOnline: switchesData.onlineCount,
                switchesOffline: switchesData.offlineCount,
                failedSwitchesGroupByStores: groupByStores(failedSupportCenterSwitches, groupByName),
                coreSwitchesOnline: (switchesData.sscCoreSwicthesTotal - coreSwitches.length),
                coreSwitchesOffline: coreSwitches.length,
                failedCoreSwitchesGroupByStores: groupByStores(coreSwitches, groupByName),
                pfsSwitchesOnline: (switchesData.sscPfsSwitchesTotal - pfsSwitches.length),
                pfsSwitchesOffline: pfsSwitches.length,
                failedPfsSwitchesGroupByStores: groupByStores(pfsSwitches, groupByName)
            }
        }
    }

    if (action.type === SET_SUPPORT_CENTER_CONTROLLERS) {
        return {
            ...state,
            supportCenters: {
                ...state.supportCenters,
                controllersOnlineCount: action.sscControllers.onlineCount,
                controllersOfflineCount: action.sscControllers.offlineCount,
                offlineControllersGroupByStores: groupByStores(action.sscControllers.offlineSSCControllers, 'siteName')
            }
        }
    }
    return state;
}


export default supportCentersReducer;