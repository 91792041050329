import { getAsync } from "./apiBase";
import store from '../store';
import { setDepotControllers, setSupportCenterControllers } from "../actions";

const getMobilityControllerStatus = async () => {
    let mobilityControllers = await getAsync('mobilitymanager/getmobilitycontrollerstatus');
    let depotControllers = mobilityControllers.filter(m => m.strDeviceType === 'Depots');
    let sscControllers = mobilityControllers.filter(m => m.strDeviceType === 'SupportCenters');

    let onlineDepotControllers = depotControllers.filter(d => d.isUp);
    let offlineDepotControllers = depotControllers.filter(d => !d.isUp);
    store.dispatch(setDepotControllers({
        onlineCount: 0,
        offlineCount: 0,
        offlineDepotControllers: []
    }));

    console.log('OFFLINE DEPOTS', offlineDepotControllers);
    let onlineSSCControllers = sscControllers.filter(d => d.isUp);
    let offlineSSCControllers = sscControllers.filter(d => !d.isUp);
    store.dispatch(setSupportCenterControllers({
        onlineCount: 0,
        offlineCount: 0,
        offlineSSCControllers: []
    }));
}

export default { getMobilityControllerStatus };