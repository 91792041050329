import React from 'react';
import '../Components/Dashboard/Dashboard.css';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const Logo = (props) => {
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Click to refresh 
        </Tooltip>
    );

    return (
        <div className='logo-container' onClick={props.onClick}>
            <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
            >
                <div className='logo'>
                    i-Watch
            </div>
            </OverlayTrigger>
        </div>
    )
}

export default Logo;