import { DeviceType, LocationType } from './constants';

const groupByStores = (offlineDevices, key) => {
    if (offlineDevices && offlineDevices.length > 0) {
        let devicesGroupByStores = [];
        let groupObject = groupBy(offlineDevices, key);
        let keys = Object.keys(groupObject);
        let values = Object.values(groupObject);
        for (let i = 0; i < keys.length; i++) {
            devicesGroupByStores.push({ site: keys[i], devices: values[i] });
        }

        let sortByStores = devicesGroupByStores.sort(function (a, b) {
            return a.devices.length - b.devices.length;
        });
        return sortByStores;
    }
}

const groupBy = (offlineDevices, key) => {
    return offlineDevices.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
}

const getDeviceName = (deviceType) => {
    let deviceName = '';
    switch (deviceType) {
        case DeviceType.AccessPoint:
            deviceName = 'Access Points';
            break;
        case DeviceType.Switch:
            deviceName = 'Switches';
            break;
        case DeviceType.CoreSwitch:
            deviceName = 'Core Switches';
            break;
        case DeviceType.PfsSwitch:
            deviceName = 'PFS Switches';
            break;
        case DeviceType.Controllers:
            deviceName = 'Controllers';
            break;
        default:
            break;
    }
    return deviceName;
}

const getLocationName = (locationType) => {
    let locationName = '';
    switch (locationType) {
        case LocationType.Stores:
            locationName = 'Stores';
            break;
        case LocationType.Depots:
            locationName = 'Depots';
            break;
        case LocationType.SSC:
            locationName = 'Support Centers';
            break;
        default:
            break;
    }

    return locationName;
}

const getCoreSwitches = (switches) => {
    return switches.filter(s => s.name.endsWith('M1-ST1') && Boolean(s.publicIpAddress));
}

const getPSFSwicthes = (switches) => {
    return switches.filter(s => s.name.endsWith('P1-ST1'));
}

const getStoresOnly = (data) => {
    return data.filter(d => d.name.startsWith('JS') || (d.site && d.site.startsWith('JS')));
}


export { groupByStores, getDeviceName, getLocationName, getCoreSwitches, getStoresOnly, getPSFSwicthes };