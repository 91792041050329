import React from 'react';

import './NetworkDashboard.css';
import { Cart } from 'react-bootstrap-icons';

const SectionTitleCard = (props) => {
    const { title, icon } = props;
    return (
        <div className='section-title'>
            <div className='title-icon'>{icon}</div>
            <h5> {title}</h5>
        </div>
    );
}

export default SectionTitleCard;