import { SET_IS_LOADING_CONTACT_CENTRE_LIST, SET_CONTACT_CENTRE_LIST } from "../actions";

const initialState = {
    isLoadingContactCentreList: false,
    contactCentreListSource: [],
    contactCentreList: []
}

const contactCentreReducer = (state = initialState, action) => {
    if (action.type === SET_IS_LOADING_CONTACT_CENTRE_LIST) {
        return {
            ...state,
            isLoadingContactCentreList: action.isLoadingContactCentreList
        }
    }

    if (action.type === SET_CONTACT_CENTRE_LIST) {
        return {
            ...state,
            contactCentreList: action.contactCentreList,
            contactCentreListSource: action.contactCentreList,
            isLoadingContactCentreList: false
        }
    }

    return state;
}

export default contactCentreReducer;