import React from 'react';
import { useSelector } from 'react-redux';

import DeviceStatusCard from '../Dashboard/DeviceStatusCard';
import DashboardRowHeader from '../Dashboard/DashboardRowHeader';
import { DeviceType, LocationType } from '../../utils/constants';

import OverAllIcon from '../../assets/icons/OverAll.svg';

const OverAllDevicesSummary = () => {

    const { isLoading, stores } = useSelector((state) => state.storesReducer);
    const { depots } = useSelector((state) => state.depotsReducer);
    const { supportCenters } = useSelector((state) => state.supportCentersReducer);
    const { golStores } = useSelector((state) => state.golStoresReducer);

    const overAllHeader = { icon: OverAllIcon, title: 'Overall Devices' };

    const overAllDevices = {
        wanOnline: stores.wanOnline + depots.wanOnline + supportCenters.wanOnline + golStores.wanOnline,
        wanOffline: stores.wanOffline + depots.wanOffline + supportCenters.wanOffline + golStores.wanOffline,
        apOnline: stores.apOnline + depots.apOnline + supportCenters.apOnline + golStores.apOnline,
        apOffline: stores.apOffline + depots.apOffline + supportCenters.apOffline + golStores.apOffline,
        assignedApOffline: stores.assignedApOffline + depots.assignedApOffline + supportCenters.assignedApOffline + golStores.assignedApOffline,
        controllerOnline: stores.controllerOnline + depots.controllerOnline + supportCenters.controllerOnline + golStores.controllerOnline,
        controllerOffline: stores.controllerOffline + depots.controllerOffline + supportCenters.controllerOffline + golStores.controllerOffline,
        switchesOnline: stores.switchesOnline + depots.switchesOnline + supportCenters.switchesOnline + golStores.switchesOnline,
        switchesOffline: stores.switchesOffline + depots.switchesOffline + supportCenters.switchesOffline + golStores.switchesOffline,
        printersOnline: stores.printersOnline + depots.printersOnline + supportCenters.printersOnline + golStores.printersOnline,
        printersOffline: stores.printersOffline + depots.printersOffline + supportCenters.printersOffline + golStores.printersOffline,
        coreSwitchesOnline: stores.coreSwitchesOnline + depots.coreSwitchesOnline + supportCenters.coreSwitchesOnline + golStores.coreSwitchesOnline,
        coreSwitchesOffline: stores.coreSwitchesOffline + depots.coreSwitchesOffline + supportCenters.coreSwitchesOffline + golStores.coreSwitchesOffline,
    };

    return (
        <div className='row-item'>
            <div className='first-column'>
                <DashboardRowHeader header={overAllHeader} />
            </div>
            <DeviceStatusCard isLoading={isLoading} onlineDevicesCount={overAllDevices.coreSwitchesOnline} offlineDevicesCount={overAllDevices.coreSwitchesOffline || 0} failedDevices={[]} deviceType={DeviceType.CoreSwitch} locationType={LocationType.All} />
            <DeviceStatusCard isLoading={isLoading} onlineDevicesCount={overAllDevices.switchesOnline} offlineDevicesCount={overAllDevices.switchesOffline} failedDevices={[]} deviceType={DeviceType.Switch} locationType={LocationType.All} />
            <DeviceStatusCard isLoading={isLoading} onlineDevicesCount={overAllDevices.printersOnline || 0} offlineDevicesCount={overAllDevices.printersOffline || 0} failedDevices={[]} deviceType={DeviceType.Unknown} locationType={LocationType.All} />
            <DeviceStatusCard isLoading={isLoading} onlineDevicesCount={overAllDevices.apOnline} offlineDevicesCount={overAllDevices.apOffline} assignedOfflineDevicesCount={overAllDevices.assignedApOffline} failedDevices={[]} assignedFailedDevices={[]} deviceType={DeviceType.AccessPoint} locationType={LocationType.All} />
            <DeviceStatusCard isLoading={isLoading} onlineDevicesCount={overAllDevices.controllerOnline || 0} offlineDevicesCount={overAllDevices.controllerOffline || 0} failedDevices={[]} deviceType={DeviceType.Unknown} locationType={LocationType.All} />
            <DeviceStatusCard isLoading={isLoading} onlineDevicesCount={overAllDevices.wanOnline || 0} offlineDevicesCount={overAllDevices.wanOffline || 0} failedDevices={[]} deviceType={DeviceType.Unknown} locationType={LocationType.All} />


        </div>
    );
}

export default OverAllDevicesSummary;