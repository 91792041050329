import React from 'react';

import LargeStatusCard from '../Cards/LargeStatusCard';
import './NetworkDashboard.css';

const SectionItemCard = (props) => {
    const { title, icon, onlineCount, offlineCount, offlineSites, onlineSites } = props;
    return (
        <div className='section-item'>
            <div className="section-item-header"> <h4>{title}</h4> {icon}</div>
            <LargeStatusCard onlineCount={onlineCount} offlineCount={offlineCount} offlineSites={offlineSites} onlineSites={onlineSites} />
        </div>
    );
}

export default SectionItemCard;