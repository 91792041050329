import axios from "axios";
import { apiBaseUrl } from '../utils/constants';

axios.defaults.headers.common["content-type"] = "application/json";
axios.defaults.headers.common.Accept = "*/*";
axios.defaults.headers.common["Ocp-Apim-Subscription-Key"] = "95c56ad1f4ee495385e673ecf1475a36";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

const getAsync = async (endpoint, options = {}) => {
    const { data = [] } = await fetchApi(`/api/${endpoint}`, options);
    return data;
};

const fetchApi = async (path, options = {}) => {
    let url = apiBaseUrl + path;
    return axios.get(url, options);
};

const postAsync = async (path, data = {}, options = {}) => {
    let url = apiBaseUrl + "/api/" + path;
    return axios.post(url, data, options);
};

const putAsync = async (path, data = {}, options = {}) => {
    let url = apiBaseUrl + "/api/" + path;
    return axios.put(url, data, options);
};

const deleteAsync = async (path, options = {}) => {
    let url = apiBaseUrl + "/api/" + path;
    return axios.delete(url, options);
};

export { getAsync, postAsync, putAsync, deleteAsync };