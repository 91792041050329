import { getAsync } from "./apiBase";
import store from '../store';
import { setIsLoadingNetworkDashboard, setNetworkHealthOverview, setNetworkDashboardOverview } from "../actions";


const getNetworkHealthOverview = async () => {
    store.dispatch(setIsLoadingNetworkDashboard(true));
    // setTimeout(async () => {
    let storesFromFailedDevices = await getAsync('networkhealth/getnetworkhealthoverview');
    store.dispatch(setNetworkDashboardOverview(storesFromFailedDevices));
    // }, 1000);
}

export default { getNetworkHealthOverview };