import React, { useState } from 'react';
import StandAloneArgosList from './StandAloneArgosList';
import DepotList from './DepotList';
import DistributionCentreList from './DistributionCentreList';
import RegionalFulfilmentCentreList from './RegionalFulfilmentCentreList';
import BankList from './BankList';
import ContactCentreList from './ContactCentreList';
import './Settings.css';

const NetworkSettings = (props) => {

    const [selectedKey, setSelectedKey] = useState('sas');

    const onSubMenuChange = (key) => {
        setSelectedKey(key);
    }

    return (
        <div className='settingSection'>
            <div className='settings-sub-menu'>
                <div className={`sub-menu-item ${selectedKey === 'sas' ? 'sub-menu-item-selected' : ''}`} onClick={() => onSubMenuChange('sas')}>Stand Alone Argos</div>
                <div className={`sub-menu-item ${selectedKey === 'depots' ? 'sub-menu-item-selected' : ''}`} onClick={() => onSubMenuChange('depots')}>Depots</div>
                <div className={`sub-menu-item ${selectedKey === 'distributioncentres' ? 'sub-menu-item-selected' : ''}`} onClick={() => onSubMenuChange('distributioncentres')}>Distribution Centres</div>
                <div className={`sub-menu-item ${selectedKey === 'rfc' ? 'sub-menu-item-selected' : ''}`} onClick={() => onSubMenuChange('rfc')}>RFC</div>
                <div className={`sub-menu-item ${selectedKey === 'bank' ? 'sub-menu-item-selected' : ''}`} onClick={() => onSubMenuChange('bank')}>Bank</div>
                <div className={`sub-menu-item border-right-none ${selectedKey === 'contactcentres' ? 'sub-menu-item-selected' : ''}`} onClick={() => onSubMenuChange('contactcentres')}>Contact Centres</div>
            </div>
            {
                selectedKey === 'sas' && <StandAloneArgosList />
            }
            {
                selectedKey === 'depots' && <DepotList />
            }
            {
                selectedKey === 'distributioncentres' && <DistributionCentreList />
            }
            {
                selectedKey === 'rfc' && <RegionalFulfilmentCentreList />
            }
            {
                selectedKey === 'bank' && <BankList />
            }
            {
                selectedKey === 'contactcentres' && <ContactCentreList />
            }
        </div>
    );
}

export default NetworkSettings;