import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import NetworkCategoryList from './NetworkCategoryList';
import contactCentreApi from '../../api/contactCentreApi';
import './Settings.css';

const ContactCentreList = () => {

    const { isLoadingContactCentreList, contactCentreList } = useSelector((state) => state.contactCentreReducer);

    useEffect(() => {
        contactCentreApi.getContactCentres();
    }, []);

    const onAddContactCentreItem = (categoryItem) => {
        contactCentreApi.addContactCentreItem(categoryItem);
    }

    const onEditContactCentreItem = (categoryItem) => {
        contactCentreApi.updateContactCentreItem(categoryItem);
    }

    const onDeleteContactCentreItem = (categoryItemId) => {
        contactCentreApi.deleteContactCentreItem(categoryItemId);
    }

    const onSearchLochsideBanks = (searchKey) => {

    }

    return (
        <NetworkCategoryList isLoadingItems={isLoadingContactCentreList}
            categoryList={contactCentreList}
            categoryName='Contact Centre'
            showSearchBox={false}
            onAddCategoryItem={onAddContactCentreItem}
            onEditCategoryItem={onEditContactCentreItem}
            onDeleteCategoryItem={onDeleteContactCentreItem}
            onSearchCategoryList={onSearchLochsideBanks} />
    );
}

export default ContactCentreList;