import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import NetworkCategoryList from './NetworkCategoryList';
import bankApi from '../../api/bankApi';
import './Settings.css';

const BankList = () => {

    const { isLoadingBankList, bankList } = useSelector((state) => state.bankReducer);

    useEffect(() => {
        bankApi.getLochsideBankList();
    }, []);

    const onAddLochsideBankItem = (categoryItem) => {
        bankApi.addLochsideBankItem(categoryItem);
    }

    const onEditLochsideBankItem = (categoryItem) => {
        bankApi.updateLochsideBankItem(categoryItem);
    }

    const onDeleteLochsideBankItem = (categoryItemId) => {
        bankApi.deleteLochsideBankItem(categoryItemId);
    }

    const onSearchLochsideBanks = (searchKey) => {

    }

    return (
        <NetworkCategoryList isLoadingItems={isLoadingBankList}
            categoryList={bankList}
            categoryName='Lochside Bank'
            showSearchBox={false}
            onAddCategoryItem={onAddLochsideBankItem}
            onEditCategoryItem={onEditLochsideBankItem}
            onDeleteCategoryItem={onDeleteLochsideBankItem}
            onSearchCategoryList={onSearchLochsideBanks} />
    );
}

export default BankList;