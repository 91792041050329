import React from 'react';
import { useSelector } from 'react-redux';

import DeviceStatusCard from '../Dashboard/DeviceStatusCard';
import DashboardRowHeader from '../Dashboard/DashboardRowHeader';
import { DeviceType, LocationType } from '../../utils/constants';

import DepotsIcon from '../../assets/icons/Depots.svg';

const DepotsSummary = () => {

    const { isLoading } = useSelector((state) => state.storesReducer);
    const { depots } = useSelector((state) => state.depotsReducer);
    const depotsHeader = { icon: DepotsIcon, title: 'Depots' };

    return (
        <div className='row-item'>
            <div className='first-column'>
                <DashboardRowHeader header={depotsHeader} />
            </div>
            <DeviceStatusCard isLoading={isLoading} onlineDevicesCount={depots.coreSwitchesOnline} offlineDevicesCount={depots.coreSwitchesOffline || 0} failedDevices={depots.failedCoreSwitchesGroupByDepots} deviceType={DeviceType.CoreSwitch} locationType={LocationType.Depots} />
            <DeviceStatusCard isLoading={isLoading} onlineDevicesCount={depots.switchesOnline} offlineDevicesCount={depots.switchesOffline} failedDevices={depots.failedSwitchesGroupByDepots} deviceType={DeviceType.Switch} locationType={LocationType.Depots} />
            <DeviceStatusCard isLoading={isLoading} onlineDevicesCount={depots.pfsSwitchesOnline || 0} offlineDevicesCount={depots.pfsSwitchesOffline || 0} failedDevices={depots.failedPfsSwitchesGroupByDepots} deviceType={DeviceType.PfsSwitch} locationType={LocationType.Depots} />
            <DeviceStatusCard isLoading={isLoading} onlineDevicesCount={depots.apOnline} offlineDevicesCount={depots.apOffline} assignedOfflineDevicesCount={depots.assignedApOffline} failedDevices={depots.failedApsGroupByDepots} assignedFailedDevices={depots.assignedFailedApsGroupByDepots} deviceType={DeviceType.AccessPoint} locationType={LocationType.Depots} />
            <DeviceStatusCard isLoading={isLoading} onlineDevicesCount={depots.controllersOnlineCount || 0} offlineDevicesCount={depots.controllersOfflineCount || 0} failedDevices={depots.offlineControllersGroupByDepots} deviceType={DeviceType.Controllers} locationType={LocationType.Depots} />
            <DeviceStatusCard isLoading={isLoading} onlineDevicesCount={depots.wanOnline || 0} offlineDevicesCount={depots.wanOffline || 0} failedDevices={[]} deviceType={DeviceType.Unknown} locationType={LocationType.Depots} />


        </div>
    );
}

export default DepotsSummary;