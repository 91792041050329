import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import store from '../../store';
import NetworkCategoryList from './NetworkCategoryList';
import depotsApi from '../../api/depotsApi';
import './Settings.css';
import { searchDepotList } from '../../actions';

const DepotList = (props) => {

    const { isLoadingDepots, depots } = useSelector((state) => state.networkDepotReducer);

    useEffect(() => {
        depotsApi.getDepots();
    }, []);

    const onAddDepotItem = (categoryItem) => {
        depotsApi.addDepot(categoryItem);
    }

    const onEditDepotItem = (categoryItem) => {
        depotsApi.updateDepot(categoryItem);
    }

    const onDeleteDepotItem = (categoryItemId) => {
        depotsApi.deleteDepot(categoryItemId);
    }

    const onSearchDepotList = (searchKey) => {
        store.dispatch(searchDepotList(searchKey));
    }

    return (
        <NetworkCategoryList isLoadingItems={isLoadingDepots}
            categoryList={depots}
            categoryName='Depot'
            showSearchBox={true}
            onAddCategoryItem={onAddDepotItem}
            onEditCategoryItem={onEditDepotItem}
            onDeleteCategoryItem={onDeleteDepotItem}
            onSearchCategoryList={onSearchDepotList} />
    );
}

export default DepotList;