import React from 'react';

import DashboardColumn from '../../Components/Dashboard/DashboardColumn';

import WanIcon from '../../assets/icons/Wan.svg';
import AccessPointIcon from '../../assets/icons/AccessPoints.svg';
import ControllersIcon from '../../assets/icons/Controllers.svg';
import SwitchesIcon from '../../assets/icons/Switches.svg';

import './Dashboard.css';

const BackgroundPanel = (props) => {

    return (
        <div className='column-container'>
            <div style={{ flex: 1 }}></div>
            <DashboardColumn deviceIcon={SwitchesIcon} title='Core Switches' />
            <DashboardColumn deviceIcon={SwitchesIcon} title='Switches' />
            <DashboardColumn deviceIcon={SwitchesIcon} title='PFS Switches' />
            <DashboardColumn deviceIcon={AccessPointIcon} title='Access Points' />
            <DashboardColumn deviceIcon={ControllersIcon} title='Controllers' />
            <DashboardColumn deviceIcon={WanIcon} title='WAN' />
        </div>
    );
}

export default BackgroundPanel;