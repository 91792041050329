import React, { useState } from 'react';
import './Dashboard.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import DevicesModal from '../Modals/DevicesModal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { getDeviceName } from '../../utils/utilities';
import { LocationType, DeviceType } from '../../utils/constants';
import DefaultCountCard from '../Cards/DefaultCountCard';
import SuccessCountCard from '../Cards/SuccessCountCard';
import FailedCountCard from '../Cards/FailedCountCard';
import InProgressFailedCard from '../Cards/InProgressFailedCard';

const DeviceStatusCard = (props) => {
    const { onlineDevicesCount, offlineDevicesCount, assignedFailedDevices, assignedOfflineDevicesCount, failedDevices, deviceType, locationType, isLoading } = props;
    // const [show, setShow] = useState(false);
    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);

    // const renderTooltip = (props) => {
    //     const deviceName = getDeviceName(deviceType);
    //     return (
    //         <Tooltip id="button-tooltip" {...props}>
    //             Click here to view the failed {deviceName}
    //         </Tooltip>
    //     );
    // }

    // const renderDefaultCards = () => {
    //     if (deviceType === DeviceType.AccessPoint) {
    //         return (
    //             <div className='device-status-card access-card'>
    //                 <DefaultCountCard />
    //                 <DefaultCountCard />
    //                 <DefaultCountCard />
    //             </div>
    //         );
    //     } else {
    //         return (
    //             <div className='device-status-card'>
    //                 <DefaultCountCard />
    //                 <DefaultCountCard />
    //             </div>
    //         );
    //     }
    // }

    // const renderOfflineCard = () => {
    //     return (
    //         <div className={offlineDevicesCount > 0 ? `status-card offline` : `status-card  default`} onClick={() => handleShow()}>
    //             <div>{offlineDevicesCount}</div>
    //         </div>
    //     );
    // }

    // const renderOnlineCard = () => {
    //     return (
    //         <div className={onlineDevicesCount > 0 ? 'status-card online' : 'status-card default'}>{onlineDevicesCount}</div>
    //     );
    // }

    // const renderAssignedCard = () => {
    //     return (
    //         <div className={assignedOfflineDevicesCount > 0 ? 'status-card assigned' : 'status-card default'}>{assignedOfflineDevicesCount}</div>
    //     );
    // }

    return (
        <div className={`device-status-card ${deviceType === DeviceType.AccessPoint ? 'access-card' : ''}`}>
            {
                isLoading
                    ? <div className='cards-container'>
                        <DefaultCountCard />
                        <DefaultCountCard />
                        {deviceType === DeviceType.AccessPoint && <DefaultCountCard />}
                    </div>
                    : <div className='cards-container'>
                        <SuccessCountCard successCount={onlineDevicesCount} />
                        {deviceType === DeviceType.AccessPoint && <InProgressFailedCard failedCount={assignedOfflineDevicesCount} failedDevices={assignedFailedDevices} deviceType={deviceType} locationType={locationType} />}
                        <FailedCountCard failedCount={offlineDevicesCount} failedDevices={failedDevices} deviceType={deviceType} locationType={locationType} />
                    </div>
            }
        </div>

    );
    // if (isLoading) {
    //     return renderDefaultCards();
    // } else if (locationType === LocationType.All) {
    //     if (deviceType === DeviceType.AccessPoint) {
    //         return (
    //             <div className='device-status-card access-card'>
    //                 {renderOnlineCard()}
    //                 {renderAssignedCard()}
    //                 {renderOfflineCard(false)}
    //             </div>
    //         );
    //     } else {
    //         return (
    //             <div className='device-status-card'>
    //                 {renderOnlineCard()}
    //                 {renderOfflineCard(false)}
    //             </div>
    //         );
    //     }
    // }
    // else {
    //     if (deviceType === DeviceType.AccessPoint) {
    //         return (
    //             <div className='device-status-card access-card'>
    //                 {renderOnlineCard()}
    //                 {
    //                     assignedOfflineDevicesCount > 0 ?
    //                         <div><OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
    //                             {renderAssignedCard()}
    //                         </OverlayTrigger>
    //                             <DevicesModal show={show} handleClose={handleClose} failedDevices={failedDevices} deviceType={deviceType} locationType={locationType} failedDevicesCount={assignedOfflineDevicesCount} />
    //                         </div>
    //                         : <div className={`status-card default`}>{assignedOfflineDevicesCount || 0}</div>
    //                 }
    //                 {
    //                     offlineDevicesCount > 0 ?
    //                         <div><OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
    //                             {renderOfflineCard(true)}
    //                         </OverlayTrigger>
    //                             <DevicesModal show={show} handleClose={handleClose} failedDevices={failedDevices} deviceType={deviceType} locationType={locationType} failedDevicesCount={offlineDevicesCount} />
    //                         </div>
    //                         : <div className={offlineDevicesCount > 0 ? 'status-card offline' : `status-card default`}>{offlineDevicesCount}</div>
    //                 }
    //             </div>
    //         );
    //     } else {
    //         return (
    //             <div className='device-status-card'>
    //                 {renderOnlineCard()}
    //                 {
    //                     offlineDevicesCount > 0 ?
    //                         <div><OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
    //                             {renderOfflineCard(true)}
    //                         </OverlayTrigger>
    //                             <DevicesModal show={show} handleClose={handleClose} failedDevices={failedDevices} deviceType={deviceType} locationType={locationType} failedDevicesCount={offlineDevicesCount} />
    //                         </div>
    //                         : <div className={offlineDevicesCount > 0 ? 'status-card offline' : `status-card default`}>{offlineDevicesCount}</div>
    //                 }
    //             </div>
    //         );
    //     }

    // }
}

export default DeviceStatusCard;