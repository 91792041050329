import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';

import { useNavigate } from 'react-router-dom';
import './SettingsPage.css';
import { ARUBA_API_AUTHTOKEN } from '../../utils/constants';
import settingApi from '../../api/settingsApi';
import ArubaSettingsForm from '../../Components/Settings/ArubaSettingsForm';
import DashboardSettingsForm from '../../Components/Settings/DashboardSettingsForm';
import NetworkSettings from '../../Components/Settings/NetworkSettings';

const SettingsPage = () => {

    const navigate = useNavigate();
    const { isAdminUser } = useSelector((state) => state.settingsReducer);
    const [selectedTabKey, setSelectedTabKey] = useState('aruba-settings');

    // useEffect(() => {
    //     if (!isAdminUser) {
    //         navigate('/');
    //     }
    // }, []);

    const onTabChange = async (key) => {
        setSelectedTabKey(key);
    }

    return (
        <div className='settingsContainer'>
            <Tab.Container id="left-tabs-example" activeKey={selectedTabKey} onSelect={(e) => onTabChange(e)}>
                <Row style={{ width: '100%', height: 'Calc( 100vh - 60px)', backgroundColor: '#dcdcdc' }}>
                    <Col sm={3} style={{ paddingRight: 0, backgroundColor: '#f2f2f2', position: 'fixed', height: '100%' }}>
                        <Nav variant="pills" className="flex-column">
                            <Nav.Item>
                                <Nav.Link eventKey="aruba-settings" className='tab-item'>Aruba Settings</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="device-overview" className='tab-item'>Dashboard Settings</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="network-settings" className='tab-item'>Network Settings</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                    <Col sm={9} style={{ backgroundColor: '#ffffff', paddingLeft: '25%', width: '100%' }}>
                        <Tab.Content>
                            <Tab.Pane eventKey="aruba-settings">
                                <ArubaSettingsForm />
                            </Tab.Pane>
                            <Tab.Pane eventKey="device-overview">
                                <DashboardSettingsForm tabKey={selectedTabKey} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="network-settings">
                                <NetworkSettings tabKey={selectedTabKey} />
                            </Tab.Pane>

                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>

        </div>
    );
}

export default SettingsPage;