import React from 'react';
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

import './Charts.css';
import { useSelector } from 'react-redux';

const HorizontalBarChartCard = (props) => {

  const { data, yAxis, dataKey, color, key } = props;
  
  const CustomToolTip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className='tooltip-container'>
          <div><b>{payload[0].payload.storeName}</b></div>
          <div>count : <b>{payload[0].payload.count}</b></div>
        </div>
      );
    }
  }

  return (
    <ResponsiveContainer width="100%" height="100%" style={{ fontSize: 13 }}>
      <ComposedChart
        key={key}
        layout="vertical"
        data={data}
        margin={{
          right: 20,
          bottom: 20,
        }}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis type="number" />
        <YAxis dataKey={yAxis} type="category" scale="band" />
        <Tooltip content={<CustomToolTip />} />
        <Legend />
        <Bar dataKey={dataKey} barSize={20} fill={color} />
      </ComposedChart>
    </ResponsiveContainer>
  );
}

export default HorizontalBarChartCard;