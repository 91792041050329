import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import store from '../../store';
import NetworkCategoryList from './NetworkCategoryList';
import standAloneArgosApi from '../../api/standAloneArgosApi';
import './Settings.css';
import { searchStandAloneArgosList } from '../../actions';

const StandAloneArgosList = (props) => {

    const { isLoadingStandAloneArgosList, standAloneArgosList } = useSelector((state) => state.standAloneArgosReducer);

    useEffect(() => {
        standAloneArgosApi.getStandAloneArgosList();
    }, []);

    const onAddStandAloneArgosItem = (categoryItem) => {
        standAloneArgosApi.addStandAloneArgos(categoryItem);
    }

    const onEditStandAloneArgosItem = (categoryItem) => {
        standAloneArgosApi.updateStandAloneArgos(categoryItem);
    }

    const onDeleteStandAloneArgosItem = (categoryItemId) => {
        standAloneArgosApi.deleteStandAloneArgos(categoryItemId);
    }

    const onSearchStandAloneArgosList = (searchKey) => {
        store.dispatch(searchStandAloneArgosList(searchKey));
    }

    return (
        <NetworkCategoryList isLoadingItems={isLoadingStandAloneArgosList}
            categoryList={standAloneArgosList}
            categoryName='Stand Alone Argos'
            showSearchBox={true}
            onAddCategoryItem={onAddStandAloneArgosItem}
            onEditCategoryItem={onEditStandAloneArgosItem}
            onDeleteCategoryItem={onDeleteStandAloneArgosItem}
            onSearchCategoryList={onSearchStandAloneArgosList} />
    );
}

export default StandAloneArgosList;