import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const MenuItem = (props) => {

    const { name, icon, navigateTo } = props;
    const navigate = useNavigate();
    const location = useLocation();
    const pathName = location.pathname;
   
    return (
        <div className={`menu-item  ${pathName === navigateTo ? 'menu-item-selected' : ''}`} onClick={() => navigate(navigateTo)}>
            {icon}
            <div className='menu-item-label'>{name}</div>
        </div>
    );
}

export default MenuItem;