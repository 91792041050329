import {
    SET_STORES_ACCESS_POINTS,
    SET_STORE_SWITCHES,
    SET_IS_LOADING_PAGE
} from '../actions';
import { groupByStores, getCoreSwitches, getPSFSwicthes } from '../utils/utilities';
import { DeviceStatus } from '../utils/constants';

const groupByName = 'site';
const initialState = {
    isLoading: false,
    lastUpdationTime: new Date(),
    stores: {
        apOnline: 0,
        apOffline: 0,
        assignedApOffline: 0,
        failedApsGroupByStores: [],
        assignedFailedApsGroupByStores: [],
        switchesOnline: 0,
        switchesOffline: 0,
        failedSwitchesGroupByStores: [],
        coreSwitchesOnline: 0,
        coreSwitchesOffline: 0,
        failedCoreSwitchesGroupByStores: [],
        pfsSwitchesOnline: 0,
        pfsSwitchesOffline: 0,
        failedPfsSwitchesGroupByStores: []
    },
    pfs: {
        apOnline: 0,
        apOffline: 0,
        failedApsGroupByStores: [],
        switchesOnline: 0,
        switchesOffline: 0,
        failedSwitchesGroupByStores: [],
        coreSwitchesOnline: 0,
        coreSwitchesOffline: 0,
        failedCoreSwitchesGroupByStores: []
    },
    accessPoints: [],
    switches: []
}

const storesReducer = (state = initialState, action) => {
    // if (action.type === SET_STORES_ACCESS_POINTS) {
    //     let aps = action.storeAccessPoints;
    //     let offlineAps = aps.filter(ap => ap.status === DeviceStatus.Down);
    //     return {
    //         ...state,
    //         accessPoints: aps,
    //         stores: {
    //             ...state.stores,
    //             apOnline: aps.filter(ap => ap.status === DeviceStatus.Up).length,
    //             apOffline: offlineAps.length,
    //             failedApsGroupByStores: groupByStores(offlineAps, groupByName)
    //         }
    //     }
    // }

    if (action.type === SET_STORES_ACCESS_POINTS) {
        let apsData = action.storeAccessPoints;
        let failedAps = apsData.failedStoresAccessPoints;
        let assignedAps = failedAps.filter(s => Boolean(s.notes));
        let offlineAps = failedAps.filter(s => !Boolean(s.notes));
        offlineAps = offlineAps.filter(f => f.site && !f.site.includes('Pre Staging not in Live'));
        return {
            ...state,
            stores: {
                ...state.stores,
                apOnline: apsData.onlineCount,
                apOffline: offlineAps.length,
                assignedApOffline: assignedAps.length,
                assignedFailedApsGroupByStores: groupByStores(assignedAps, groupByName),
                failedApsGroupByStores: groupByStores(offlineAps, groupByName)
            }
        }
    }

    // if (action.type === SET_STORE_SWITCHES) {
    //     let switches = action.storeSwitches;
    //     let offlineSwicthes = switches.filter(s => s.status === DeviceStatus.Down);
    //     return {
    //         ...state,
    //         switches: switches,
    //         stores: {
    //             ...state.stores,
    //             switchesOnline: switches.filter(switches => switches.status === DeviceStatus.Up).length,
    //             switchesOffline: offlineSwicthes.length,
    //             failedSwitchesGroupByStores: groupByStores(offlineSwicthes, groupByName)
    //         }
    //     }
    // }

    if (action.type === SET_STORE_SWITCHES) {
        let switchesData = action.storeSwitches;
        let failedStoresSwitches = switchesData.failedStoresSwitches.filter(s => s.site && !s.site.includes('Pre Staging not in Live'));
        // let normalSwitches = failedStoresSwitches.filter(s => !s.name.endsWith('M1-ST1'));
        let coreSwitches = getCoreSwitches(failedStoresSwitches);
        let pfsSwitches = getPSFSwicthes(failedStoresSwitches);
        return {
            ...state,
            stores: {
                ...state.stores,
                switchesOnline: switchesData.onlineCount,
                switchesOffline: failedStoresSwitches.length,
                failedSwitchesGroupByStores: groupByStores(failedStoresSwitches, groupByName),
                coreSwitchesOnline: (switchesData.storesCoreSwicthesTotal - coreSwitches.length),
                coreSwitchesOffline: coreSwitches.length,
                failedCoreSwitchesGroupByStores: groupByStores(coreSwitches, groupByName),
                pfsSwitchesOnline: (switchesData.storesPfsSwicthesTotal - pfsSwitches.length),
                pfsSwitchesOffline: pfsSwitches.length,
                failedPfsSwitchesGroupByStores: groupByStores(pfsSwitches, groupByName)
            },
            pfs: {
                ...state.pfs,
                switchesOffline: pfsSwitches.length,
                failedSwitchesGroupByStores: groupByStores(pfsSwitches, groupByName),
            },
            lastUpdationTime: new Date()
        }
    }

    if (action.type === SET_IS_LOADING_PAGE) {
        return {
            ...state,
            isLoading: action.isLoading
        }
    }
    return state;
}


export default storesReducer;