import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import settingsApi from '../../api/settingsApi';
import './Settings.css';

const ArubaSettingsForm = () => {

    const navigate = useNavigate();

    const [accessToken, setAccessToken] = useState('');
    const [refreshToken, setRefreshToken] = useState('');

    const onSave = async () => {
        if (accessToken && refreshToken) {
            let authToken = { accessToken: accessToken, refreshToken: refreshToken };
            await settingsApi.saveAuthToken(authToken);
            navigate('/');
        }
    }

    const onClear = () => {
        setAccessToken('');
        setRefreshToken('');
    }

    return (
        <div className='settingSection'>
            <h4>Aruba Settings</h4>
            <Form style={{ width: '35%' }}>
                <Form.Group className="mb-3" controlId="arubaForm.ControlInput1">
                    <Form.Label>AccessToken</Form.Label>
                    <Form.Control type="text" placeholder="Access Token" value={accessToken} onChange={(e) => setAccessToken(e.target.value)} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="arubaForm.ControlInput2">
                    <Form.Label>Refresh Token</Form.Label>
                    <Form.Control type="text" placeholder="Refresh Token" value={refreshToken} onChange={(e) => setRefreshToken(e.target.value)} />
                </Form.Group>
                <Button variant="secondary" onClick={() => onSave()} className='primary-button'>Save</Button>{' '}
                <Button variant="outline-dark" onClick={() => onClear()} style={{ marginLeft: 15 }}>Clear</Button>{' '}
            </Form>
        </div>
    );
}

export default ArubaSettingsForm;