import React from 'react';

const CartIcon = (props) => {

    return (
        <svg width={props.width || 24} height={props.height || 24} viewBox="0 0 24 24">
            <title>icon/trolley_24px</title>
            <desc>Created with Sketch.</desc>
            <defs></defs>
            <g id="icon/trolley_24px" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <rect id="holder" x="0" y="0" width="24" height="24"></rect>
                <g id="ic_trolley" transform="translate(1.000000, 0.000000)" fill={props.fill}>
                    <circle id="shape" cx="17" cy="21" r="2"></circle>
                    <circle id="shape" cx="7" cy="21" r="2"></circle>
                    <path d="M3.304413,6.56996686 L5.21141834,14.8786809 C5.36202712,15.5348748 5.94600121,16 6.61925703,16 L17.1863367,16 C17.8593368,16 18.4431604,15.5352204 18.5940223,14.8793469 L19.7544445,9.83440225 C19.7995412,9.63834375 19.8223069,9.43781169 19.8223069,9.23663353 C19.8223069,7.7638742 18.6283995,6.56996686 17.1556402,6.56996686 L3.304413,6.56996686 Z M3.304413,6.56996686 L2.0431994,6.56996686 C1.60561389,6.56996686 1.23367386,6.28890517 1.09805758,5.89746008 C1.07499119,5.8308804 1.05876183,5.76110964 1.05020853,5.68898551 C1.04558013,5.64995739 1.0431994,5.61024015 1.0431994,5.56996686 C1.0431994,5.01768211 1.49091465,4.56996686 2.0431994,4.56996686 L17.1556402,4.56996686 C19.732969,4.56996686 21.8223069,6.6593047 21.8223069,9.23663353 C21.8223069,9.58869531 21.782467,9.93962642 21.7035477,10.2827288 L20.5431255,15.3276735 C20.1833778,16.8916793 18.7911832,18 17.1863367,18 L6.61925703,18 C5.01380084,18 3.62124723,16.8908552 3.26210323,15.3260852 L0.025342445,1.22370215 C-0.0982048392,0.685413643 0.238009349,0.148889729 0.776297853,0.025342445 C1.31458636,-0.0982048392 1.85111027,0.238009349 1.97465756,0.776297853 L3.304413,6.56996686 Z" id="shape" fill-rule="nonzero"></path>
                </g>
            </g>
        </svg>
    );
}

export default CartIcon;