import { setStoreAccessPoints, setStoreSwitches, setDepotAccessPoints, setSSCSwitches, setGolStoreAccessPoints, setGolStoreSwitches, setSSCAccessPoints, setIsLoadingPage } from '../actions';
import store from '../store';
import { getAsync } from "./apiBase";
import { setDepotSwitches } from '../actions/setDepotSwitches';
import golStores from '../utils/golStoresData';
import settingsApi from './settingsApi';

const getAccessPoints = async () => {
    const accessPoints = await getAsync('stores/getaccesspoints');
    const depotsAccessPoints = accessPoints.filter(ap => ap.name.startsWith('DP') || (ap.site && ap.site.startsWith('DP')));
    const storesAccessPoints = accessPoints.filter(ap => ap.name.startsWith('JS') || ap.name.startsWith('TS'));
    store.dispatch(setStoreAccessPoints(storesAccessPoints));
    store.dispatch(setDepotAccessPoints(depotsAccessPoints));
}

const getSwitches = async () => {
    const switches = await getAsync('stores/getswitches');
    const storesSwitches = switches.filter(s => s.name.startsWith('JS') || s.name.startsWith('TS'));
    const depotsSwitches = switches.filter(s => s.name.startsWith('DP') || (s.site && s.site.startsWith('DP')));
    store.dispatch(setStoreSwitches(storesSwitches));
    store.dispatch(setDepotSwitches(depotsSwitches));
}

const getDeviceTotalByStore = async (storeName, deviceType) => {
    const deviceTotal = await getAsync('stores/getdevicetotalbystore/' + storeName + '/' + deviceType);
    return deviceTotal;
}

const setStoresAccessPointsData = (accessPointsData, devicesOverview) => {
    const onlyStores = accessPointsData.failedAccessPoints.filter(ap => !golStores.includes(ap.site));
    const failedStoresAccessPoints = onlyStores.filter(ap => !ap.name.startsWith('DP') || !(ap.site && ap.site.startsWith('DP')));
    store.dispatch(setStoreAccessPoints({ onlineCount: (devicesOverview.storesApsTotal - failedStoresAccessPoints.length) || 0, offlineCount: failedStoresAccessPoints.length, failedStoresAccessPoints: failedStoresAccessPoints }));
}

const setDepotsAccessPointsData = (accessPointsData, devicesOverview) => {
    const failedDepotsAccessPoints = accessPointsData.failedAccessPoints.filter(ap => ap.name.startsWith('DP') || (ap.site && ap.site.startsWith('DP')));
    store.dispatch(setDepotAccessPoints({ onlineCount: (devicesOverview.depotApsTotal - failedDepotsAccessPoints.length) || 0, offlineCount: failedDepotsAccessPoints.length, failedDepotsAccessPoints: failedDepotsAccessPoints }));
}

const setGolStoresAccessPointsData = (accessPointsData, devicesOverview) => {
    const failedGolStoresAccessPoints = accessPointsData.failedAccessPoints.filter(ap => golStores.includes(ap.site));
    store.dispatch(setGolStoreAccessPoints({ onlineCount: (devicesOverview.golStoresApsTotal - failedGolStoresAccessPoints.length) || 0, offlineCount: failedGolStoresAccessPoints.length, failedStoresAccessPoints: failedGolStoresAccessPoints }));
}

const setSSCAccessPointsData = (accessPointsData, devicesOverview) => {
    const failedSSCAccessPoints = accessPointsData.failedAccessPoints.filter(s => s.name.startsWith('BC') || s.name.startsWith('IC') || (s.site && s.site.startsWith('BC')) || (s.site && s.site.startsWith('IC')));
    store.dispatch(setSSCAccessPoints({ onlineCount: (devicesOverview.sscApsTotal - failedSSCAccessPoints.length) || 0, offlineCount: failedSSCAccessPoints.length, failedSSCAccessPoints: failedSSCAccessPoints }));
}

const getAccessPointsData = async (accessToken, devicesOverview) => {
    try {
        const accessPointsData = await getAsync('stores/getaccesspointdata/' + accessToken);
        setStoresAccessPointsData(accessPointsData, devicesOverview);
        setDepotsAccessPointsData(accessPointsData, devicesOverview);
        setGolStoresAccessPointsData(accessPointsData, devicesOverview);
        setSSCAccessPointsData(accessPointsData, devicesOverview);
        return accessPointsData;
    } catch (error) {
        await settingsApi.getRefreshToken();
        await getAccessPointsData(accessToken, devicesOverview);
    }
}

const setStoresSwitchesData = (switchesData, devicesOverview) => {
    var failedSwitches = switchesData.failedSwitches.filter(s => s.serial !== 'SG02JQK0NF' && s.serial !== 'CN97K910PW');
    const onlyStores = failedSwitches.filter(s => !golStores.includes(s.site));
    const offlineStoresSwitches = onlyStores.filter(s => s.name.startsWith('JS') || (s.site && s.site.startsWith('JS')));
    store.dispatch(setStoreSwitches({
        onlineCount: (devicesOverview.storesSwitchesTotal - offlineStoresSwitches.length) || 0,
        offlineCount: offlineStoresSwitches.length,
        failedStoresSwitches: offlineStoresSwitches,
        storesCoreSwicthesTotal: devicesOverview.storesCoreSwicthesTotal || 0,
        storesPfsSwicthesTotal: devicesOverview.storesPfsSwicthesTotal || 0
    }));
}

const setDepotsSwitchesData = (switchesData, devicesOverview) => {
    const failedDepotsSwitches = switchesData.failedSwitches.filter(s => s.name.startsWith('DP') || (s.site && s.site.startsWith('DP')));
    store.dispatch(setDepotSwitches({
        onlineCount: (devicesOverview.depotSwicthesTotal - failedDepotsSwitches.length) || 0,
        offlineCount: failedDepotsSwitches.length,
        failedDepotsSwitches: failedDepotsSwitches,
        depotCoreSwicthesTotal: devicesOverview.depotCoreSwicthesTotal || 0,
        depotPfsSwitchesTotal: devicesOverview.depotPfsSwicthesTotal || 0
    }));
}

const setSSCSwitchesData = (switchesData, devicesOverview) => {
    const failedSupportCenterSwitches = switchesData.failedSwitches.filter(s => s.name.startsWith('BC') || s.name.startsWith('IC') || (s.site && s.site.startsWith('BC')) || (s.site && s.site.startsWith('IC')));
    store.dispatch(setSSCSwitches({
        onlineCount: (devicesOverview.sscSwicthesTotal - failedSupportCenterSwitches.length) || 0,
        offlineCount: failedSupportCenterSwitches.length,
        failedSupportCenterSwitches: failedSupportCenterSwitches,
        sscCoreSwicthesTotal: devicesOverview.sscCoreSwicthesTotal || 0,
        sscPfsSwitchesTotal: devicesOverview.sscPfsSwicthesTotal || 0
    }));
}

const setGolStoresSwitchesData = (switchesData, devicesOverview) => {
    var failedSwitches = switchesData.failedSwitches.filter(s => s.serial !== 'SG02JQK0NF' && s.serial !== 'CN97K910PW');
    const failedGolStoresSwitches = failedSwitches.filter(s => golStores.includes(s.site));
    store.dispatch(setGolStoreSwitches({
        onlineCount: (devicesOverview.golStoresSwicthesTotal - failedGolStoresSwitches.length) || 0,
        offlineCount: failedGolStoresSwitches.length,
        failedGolStoresSwitches: failedGolStoresSwitches,
        golStoresCoreSwicthesTotal: devicesOverview.golStoresCoreSwicthesTotal || 0,
        golStoresPfsSwicthesTotal: devicesOverview.golStoresPfsSwicthesTotal || 0
    }));
}

const getSwitchesData = async (accessToken, devicesOverview) => {
    const switchesData = await getAsync('stores/getswitchesdata/' + accessToken);
    setStoresSwitchesData(switchesData, devicesOverview);
    setDepotsSwitchesData(switchesData, devicesOverview);
    setSSCSwitchesData(switchesData, devicesOverview);
    setGolStoresSwitchesData(switchesData, devicesOverview);
    return switchesData;
}

export default { getAccessPoints, getSwitches, getAccessPointsData, getSwitchesData, getDeviceTotalByStore };

//let onlineSwitches = switchesData.onlineSwitches;
    //let failedSwitches = switchesData.failedSwitches;
    //const storesSwitches = onlineSwitches.filter(s => s.name.startsWith('JS') || (s.site && s.site.startsWith('JS')));
    // const depotsSwitches = onlineSwitches.filter(s => s.name.startsWith('DP') || (s.site && s.site.startsWith('DP')));
    //const supportCenterSwicthes = onlineSwitches.filter(s => s.name.startsWith('BC') || s.name.startsWith('IC') || (s.site && s.site.startsWith('BC')) || (s.site && s.site.startsWith('IC')));
    //const failedStoresSwitches = failedSwitches.filter(s => s.name.startsWith('JS') || (s.site && s.site.startsWith('JS')));
    //const failedDepotsSwitches = failedSwitches.filter(s => s.name.startsWith('DP') || (s.site && s.site.startsWith('DP')));
    //const failedSupportCenterSwitches = failedSwitches.filter(s => s.name.startsWith('BC') || s.name.startsWith('IC') || (s.site && s.site.startsWith('BC')) || (s.site && s.site.startsWith('IC')));
    //store.dispatch(setStoreSwitches({ onlineCount: storesSwitches.length, offlineCount: failedStoresSwitches.length, failedStoresSwitches: failedStoresSwitches }));
    // store.dispatch(setDepotSwitches({ onlineCount: depotsSwitches.length, offlineCount: failedDepotsSwitches.length, failedDepotsSwitches: failedDepotsSwitches }));
    //store.dispatch(setSSCSwitches({ onlineCount: supportCenterSwicthes.length, offlineCount: failedSupportCenterSwitches.length, failedSupportCenterSwitches: failedSupportCenterSwitches }));