import React from 'react';
import { useSelector } from 'react-redux';


import DeviceStatusCard from '../Dashboard/DeviceStatusCard';
import DashboardRowHeader from '../Dashboard/DashboardRowHeader';
import { DeviceType, LocationType } from '../../utils/constants';

import StoresIcon from '../../assets/icons/Stores.svg';

const GolSummary = () => {

    const { isLoading } = useSelector((state) => state.storesReducer);
    const { golStores } = useSelector((state) => state.golStoresReducer);
    const storesHeader = { icon: StoresIcon, title: 'GOL Stores' };

    return (
        <div className='row-item'>
            <div className='first-column'>
                <DashboardRowHeader header={storesHeader} />
            </div>

            <DeviceStatusCard isLoading={isLoading} onlineDevicesCount={golStores.coreSwitchesOnline} offlineDevicesCount={golStores.coreSwitchesOffline || 0} failedDevices={golStores.failedCoreSwitchesGroupByStores} deviceType={DeviceType.CoreSwitch} locationType={LocationType.Stores} />
            <DeviceStatusCard isLoading={isLoading} onlineDevicesCount={golStores.switchesOnline} offlineDevicesCount={golStores.switchesOffline} failedDevices={golStores.failedSwitchesGroupByStores} deviceType={DeviceType.Switch} locationType={LocationType.Stores} />
            <DeviceStatusCard isLoading={isLoading} onlineDevicesCount={golStores.pfsSwitchesOnline || 0} offlineDevicesCount={golStores.pfsSwitchesOffline || 0} failedDevices={golStores.failedPfsSwitchesGroupByStores} deviceType={DeviceType.PfsSwitch} locationType={LocationType.Stores} />
            <DeviceStatusCard isLoading={isLoading} onlineDevicesCount={golStores.apOnline} offlineDevicesCount={golStores.apOffline} assignedOfflineDevicesCount={golStores.assignedApOffline} failedDevices={golStores.failedApsGroupByStores} assignedFailedDevices={golStores.assignedFailedApsGroupByStores} deviceType={DeviceType.AccessPoint} locationType={LocationType.Stores} />
            <DeviceStatusCard isLoading={isLoading} onlineDevicesCount={golStores.controllerOnline || 0} offlineDevicesCount={golStores.controllerOffline || 0} failedDevices={[]} deviceType={DeviceType.Unknown} locationType={LocationType.Stores} />
            <DeviceStatusCard isLoading={isLoading} onlineDevicesCount={golStores.wanOnline || 0} offlineDevicesCount={golStores.wanOffline || 0} failedDevices={[]} deviceType={DeviceType.Unknown} locationType={LocationType.Stores} />
        </div>
    );
}

export default GolSummary;