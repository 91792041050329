import { postAsync, getAsync, putAsync } from "./apiBase";
import {
    setStores, setAccessPointsChartsData, setIsLoadingAPChartData, setSwitchesChartsData,
    setIsLoadingTop10APData, setTop10AccessPoints, setIsLoadingTop10Switches, setTop10Switches, setIsLoadingSwitchesChartData, setIsLoadingChartPage
} from "../actions";
import store from '../store';
import { timeIntervalType } from "../utils/constants";

const getStoresFromFailedDevices = async () => {
    store.dispatch(setIsLoadingChartPage(true));
    let storesFromFailedDevices = await getAsync('stores/GetStoresFromFailedDevices');
    store.dispatch(setStores(storesFromFailedDevices));
}

const getTimeIntervals = async () => {
    let timeIntervals = await getAsync('core/GetTimeIntervals');
    store.dispatch(timeIntervals);
}

const getSwitchesChartData = async (intervalType, startDate, endDate, storeName) => {
    let url = `stores/GetSwitchesChartData/${intervalType}`;
    if (storeName) {
        url = `${url}?storeName=${storeName}`;
        if (startDate && endDate) {
            url = `${url}&startDate=${startDate}&endDate=${endDate}`;
        }
    }
    if (startDate && endDate) {
        url = `${url}?startDate=${startDate}&endDate=${endDate}`;
    }
    store.dispatch(setIsLoadingSwitchesChartData(true));
    let switchesChartData = await getAsync(url);
    store.dispatch(setSwitchesChartsData(switchesChartData));
}

const getAccessPointsChartData = async (intervalType, startDate, endDate, storeName) => {
    let url = `stores/GetAccesspointsChartData/${intervalType}`;
    if (storeName) {
        url = `${url}?storeName=${storeName}`;
        if (startDate && endDate) {
            url = `${url}&startDate=${startDate}&endDate=${endDate}`;
        }
    }
    if (startDate && endDate) {
        url = `${url}?startDate=${startDate}&endDate=${endDate}`;
    }
    store.dispatch(setIsLoadingAPChartData(true));
    let accessPointsChartData = await getAsync(url);
    store.dispatch(setAccessPointsChartsData(accessPointsChartData));
}

const getTop10AccessPoints = async (intervalType, startDate, endDate) => {
    let url = `stores/GetTop10FailedDevices/${intervalType}`;
    if (startDate && endDate) {
        url = `${url}?startDate=${startDate}&endDate=${endDate}`;
    }
    store.dispatch(setIsLoadingTop10APData(true));
    let top10AccessPoints = await getAsync(url);
    store.dispatch(setTop10AccessPoints(top10AccessPoints));
}

const getTop10Switches = async (intervalType, startDate, endDate) => {
    let url = `stores/GetTop10FailedSwitches/${intervalType}`;
    if (startDate && endDate) {
        url = `${url}?startDate=${startDate}&endDate=${endDate}`;
    }
    store.dispatch(setIsLoadingTop10Switches(true));
    let top10Switches = await getAsync(url);
    store.dispatch(setTop10Switches(top10Switches));
}

export default { getStoresFromFailedDevices, getTimeIntervals, getAccessPointsChartData, getTop10AccessPoints, getTop10Switches, getSwitchesChartData };

