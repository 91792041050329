import React from 'react';

import './Card.css';

const SuccessCountCard = (props) => {
    return (
        <div className={props.successCount > 0 ? 'count-card success-count' : 'count-card default-count'}>{props.successCount}</div>
    );
}

export default SuccessCountCard;