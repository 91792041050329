import { SET_IS_LOADING_DISTRIBUTION_CENTRES, SET_DISTRIBUTION_CENTRES, SEARCH_DISTRIBUTION_CENTRES } from "../actions";

const initialState = {
    isLoadingDistributionCentres: false,
    distributionCentreListSource: [],
    distributionCentres: []
}

const distributionCentresReducer = (state = initialState, action) => {
    if (action.type === SET_IS_LOADING_DISTRIBUTION_CENTRES) {
        return {
            ...state,
            isLoadingDistributionCentres: action.isLoadingDistributionCentres
        }
    }

    if (action.type === SET_DISTRIBUTION_CENTRES) {
        return {
            ...state,
            distributionCentres: action.distributionCentres,
            distributionCentreListSource: action.distributionCentres,
            isLoadingDistributionCentres: false
        }
    }

    if (action.type === SEARCH_DISTRIBUTION_CENTRES) {
        let dcList = state.distributionCentreListSource.filter(s => s.siteName.toLowerCase().includes(action.searchKey.toLowerCase()));
        return {
            ...state,
            distributionCentres: dcList
        }
    }

    return state;
}

export default distributionCentresReducer;