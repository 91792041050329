
import { postAsync, getAsync, putAsync, deleteAsync } from "./apiBase";
import store from '../store';
import { setIsDeletingCategory, setIsSavingCategory, setCategoryApiResponse, setRegionalFulfilmentCentres, setIsLoadingRegionalFulfilmentCentres } from "../actions";

const getRegionalFulfilmentCentres = async () => {
    store.dispatch(setIsLoadingRegionalFulfilmentCentres(true));
    const rfcList = await getAsync('rfc/getrfclist');
    store.dispatch(setRegionalFulfilmentCentres(rfcList))
}

const addRegionalFulfilmentCentre = async (regionalFulfilmentCentreItem) => {
    store.dispatch(setIsSavingCategory(true));
    var response = await postAsync('rfc/addrfcitem', regionalFulfilmentCentreItem);
    store.dispatch(setCategoryApiResponse(response.data));
    store.dispatch(setIsSavingCategory(false));
    await getRegionalFulfilmentCentres();
}

const updateRegionalFulfilmentCentre = async (regionalFulfilmentCentreItem) => {
    store.dispatch(setIsSavingCategory(true));
    var response = await putAsync('rfc/updaterfcitem', regionalFulfilmentCentreItem);
    store.dispatch(setCategoryApiResponse(response.data));
    store.dispatch(setIsSavingCategory(false));
    await getRegionalFulfilmentCentres();
}

const deleteRegionalFulfilmentCentre = async (regionalFulfilmentCentreId) => {
    store.dispatch(setIsDeletingCategory(true));
    var response = await deleteAsync('rfc/deleterfcitem/' + regionalFulfilmentCentreId);
    store.dispatch(setCategoryApiResponse(response.data));
    store.dispatch(setIsDeletingCategory(false));
    await getRegionalFulfilmentCentres();
}

export default { getRegionalFulfilmentCentres, addRegionalFulfilmentCentre, updateRegionalFulfilmentCentre, deleteRegionalFulfilmentCentre };