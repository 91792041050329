import { SET_IS_LOADING_BANK_LIST, SET_BANK_LIST } from "../actions";

const initialState = {
    isLoadingBankList: false,
    bankListSource: [],
    bankList: []
}

const bankReducer = (state = initialState, action) => {
    if (action.type === SET_IS_LOADING_BANK_LIST) {
        return {
            ...state,
            isLoadingBankList: action.isLoadingBankList
        }
    }

    if (action.type === SET_BANK_LIST) {
        return {
            ...state,
            bankList: action.bankList,
            bankListSource: action.bankList,
            isLoadingBankList: false
        }
    }

    return state;
}

export default bankReducer;