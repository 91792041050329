import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import './SitesModal.css';
import { Row, Col, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';


const DeleteConfirmationModal = (props) => {

    const { show, handleClose, categoryItem, onDeleteCategoryItem } = props;
    const { isDeletingCategory } = useSelector((state) => state.settingsReducer);

    return (
        <Modal show={show} onHide={handleClose} size='md'>
            <Modal.Header closeButton>
                <Modal.Title style={{ color: 'Red', fontSize: 20 }}> Delete Confirmation </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{ color: '#818181', fontSize: 16 }}>Do you want to delete this item <span style={{ color: '#404040', fontWeight: 600 }}>{categoryItem.coreSwitchName}</span>?</div>
            </Modal.Body>
            <Modal.Footer >
                <Button className='primary-button' onClick={() => onDeleteCategoryItem(categoryItem.id)}>{isDeletingCategory ? 'Deleting...' : 'Yes'}</Button>{' '}
                <Button variant="outline-dark" onClick={() => handleClose()} style={{ borderRadius: 0, marginLeft: 15 }}>No</Button>{' '}
            </Modal.Footer>
        </Modal>
    );

}

export default DeleteConfirmationModal;