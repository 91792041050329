import React from 'react';
import './Dashboard.css';

const DashboardColumn = (props) => {

    const { deviceIcon, title } = props;
    return (
        <div className='dashboard-column' style={{ width: title === 'Access Points' ? '16%' : '13%' }}>
            <div className='column-header'>
                <img src={deviceIcon} width={25} />
                <div className='column-title'> {title}</div>
            </div>
        </div>
    )
}

export default DashboardColumn;