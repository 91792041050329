import { SET_IS_LOADING_DEPOTS, SET_DEPOTS, SEARCH_DEPOT_LIST } from "../actions";

const initialState = {
    isLoadingDepots: false,
    depotListSource: [],
    depots: []
}

const networkDepotReducer = (state = initialState, action) => {
    if (action.type === SET_IS_LOADING_DEPOTS) {
        return {
            ...state,
            isLoadingDepots: action.isLoadingDepots
        }
    }

    if (action.type === SET_DEPOTS) {
        return {
            ...state,
            depots: action.depots,
            depotListSource: action.depots,
            isLoadingDepots: false
        }
    }

    if (action.type === SEARCH_DEPOT_LIST) {
        let depotList = state.depotListSource.filter(s => s.siteName.toLowerCase().includes(action.searchKey.toLowerCase()));
        return {
            ...state,
            depots: depotList
        }
    }


    return state;
}

export default networkDepotReducer;