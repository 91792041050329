
import { postAsync, getAsync, putAsync, deleteAsync } from "./apiBase";
import store from '../store';
import { setIsDeletingCategory, setIsSavingCategory, setCategoryApiResponse, setIsLoadingBankList, setBankList } from "../actions";

const getLochsideBankList = async () => {
    store.dispatch(setIsLoadingBankList(true));
    const bankList = await getAsync('bank/getlochsidebanklist');
    store.dispatch(setBankList(bankList))
}

const addLochsideBankItem = async (lochsideBankItem) => {
    store.dispatch(setIsSavingCategory(true));
    var response = await postAsync('bank/addlochsidebankitem', lochsideBankItem);
    store.dispatch(setCategoryApiResponse(response.data));
    store.dispatch(setIsSavingCategory(false));
    await getLochsideBankList();
}

const updateLochsideBankItem = async (lochsideBankItem) => {
    store.dispatch(setIsSavingCategory(true));
    var response = await putAsync('bank/updatelochsidebankitem', lochsideBankItem);
    store.dispatch(setCategoryApiResponse(response.data));
    store.dispatch(setIsSavingCategory(false));
    await getLochsideBankList();
}

const deleteLochsideBankItem = async (lochsideBankId) => {
    store.dispatch(setIsDeletingCategory(true));
    var response = await deleteAsync('bank/deletelochsidebankitem/' + lochsideBankId);
    store.dispatch(setCategoryApiResponse(response.data));
    store.dispatch(setIsDeletingCategory(false));
    await getLochsideBankList();
}

export default { getLochsideBankList, addLochsideBankItem, updateLochsideBankItem, deleteLochsideBankItem };