import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { PencilFill, Trash3Fill, PlusCircle, Search } from 'react-bootstrap-icons';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import store from '../../store';
import { setCategoryApiResponse } from '../../actions';
import CreateOrUpdateCategoryModal from '../Modals/CreateOrUpdateCategoryModal';
import { Alert, Spinner } from 'react-bootstrap';
import DeleteConfirmationModal from '../Modals/DeleteConfirmationModal';

const NetworkCategoryList = (props) => {

    const { isLoadingItems, categoryList, categoryName, onAddCategoryItem, showSearchBox,
        onEditCategoryItem, onDeleteCategoryItem, onSearchCategoryList } = props;
    const { categoryApiResponse } = useSelector((state) => state.settingsReducer);
    const [siteName, setSiteName] = useState('');
    const [selectedCategory, setSelectedCategory] = useState();
    const [showCreateOrUpdateModal, setShowCreateOrUpdateModal] = useState(false);
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);

    useEffect(() => {
        if (categoryApiResponse) {
            setShowDeleteConfirmationModal(false);
            setTimeout(() => {
                store.dispatch(setCategoryApiResponse(null));
            }, 5000);
        }
    }, [categoryApiResponse]);

    const onChangeSearchBoxText = (searchKey) => {
        setSiteName(searchKey);
        onSearchCategoryList(searchKey);
    }

    const onAddNewButtonClick = () => {
        const categoryItem = {
            coreSwitchName: "",
            siteName: "",
            ipAddress: "",
            source: 1
        };
        setSelectedCategory(categoryItem);
        setShowCreateOrUpdateModal(true);
    }

    const onEditButtonClick = (categoryItem) => {
        setSelectedCategory(categoryItem);
        setShowCreateOrUpdateModal(true);
    }

    const onDeleteButtonClick = (categoryItem) => {
        setSelectedCategory(categoryItem);
        setShowDeleteConfirmationModal(true);
    }


    const renderEditTooltip = (props) => {
        return (
            <Tooltip id="button-tooltip" {...props}>
                Edit
            </Tooltip>
        );
    }

    const renderDeleteTooltip = (props) => {
        return (
            <Tooltip id="button-tooltip" {...props}>
                Delete
            </Tooltip>
        );
    }

    return (
        <div className='content-container'>
            <div className='search-box mb-2'>
                {
                    showSearchBox ? <Form.Group controlId="networkSettings.ControlInput1">
                        <Form.Control type="text" placeholder="Search By Site Name" value={siteName} onChange={(e) => onChangeSearchBoxText(e.target.value)} />
                    </Form.Group> : <div className='category-title'>  </div>
                }

                <Button type='button' className='primary-button' onClick={() => onAddNewButtonClick()}>
                    <PlusCircle size={18} color="#fff" style={{ marginRight: 5 }} />  {'Add New'}
                </Button>{' '}
            </div>
            <div className='devices-container'>
                {
                    isLoadingItems ? <div className='network-items-loader'>
                        <Spinner
                            as="span"
                            animation="border"
                            role="status"
                            aria-hidden="true"
                        /><span style={{ marginLeft: 12, color: '#f06c00', fontSize: 18, }}> Fetching data...</span>
                    </div>
                        : <div>
                            {
                                categoryList.length > 0 ? categoryList.map(categoryItem => <div className='device-item'>
                                    <div className='device-item-first-column'> <b>{categoryItem.coreSwitchName || '_'}</b> <div className='device-label'>Core Switch Name</div> </div>
                                    <div className='device-item-column'> <b>{categoryItem.siteName || '_'}</b> <div className='device-label'>Site Name</div> </div>
                                    <div className='device-item-column'> <b>{categoryItem.networkSource || '_'}</b> <div className='device-label'>Network Source</div> </div>
                                    <div className='device-item-column'> <b>{categoryItem.ipAddress || '_'}</b> <div className='device-label'>Ip Address</div> </div>
                                    <div className='device-item-action'>
                                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderEditTooltip}>
                                            <span className='edit-icon' onClick={() => onEditButtonClick(categoryItem)}><PencilFill color='#404040' /></span>
                                        </OverlayTrigger>
                                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderDeleteTooltip}>
                                            <span className='edit-icon' onClick={() => onDeleteButtonClick(categoryItem)}><Trash3Fill color='#404040' /></span>
                                        </OverlayTrigger>
                                    </div>
                                </div>)
                                    : <div className='empty-results-container'>No records found</div>
                            }
                        </div>
                }

            </div>
            {selectedCategory && <CreateOrUpdateCategoryModal show={showCreateOrUpdateModal}
                handleClose={() => setShowCreateOrUpdateModal(false)}
                onAddCategoryItem={onAddCategoryItem}
                onEditCategoryItem={onEditCategoryItem}
                categoryItem={selectedCategory} categoryName={categoryName} />}

            {categoryApiResponse != null && <div style={{ position: "absolute", top: 12, width: '60%', zIndex: 9 }}>
                <Alert key='alert-success' variant={'success'} dismissible className='alert-box-success'>
                    {categoryApiResponse.message}
                </Alert>
            </div>}

            {selectedCategory && <DeleteConfirmationModal show={showDeleteConfirmationModal}
                handleClose={() => setShowDeleteConfirmationModal(false)}
                onDeleteCategoryItem={onDeleteCategoryItem}
                categoryItem={selectedCategory} categoryName={categoryName} />}
        </div>
    );
}

export default NetworkCategoryList;