import { SET_STORES, SET_ACCESS_POINTS_CHART_DATA, SET_TOP_10_ACCESS_POINTS, SET_IS_LOADING_TOP_10_AP_DATA, SET_TOP_10_SWITCHES, SET_IS_LOADING_TOP_10_SWITCHES, SET_IS_LOADING_CHART_PAGE, SET_IS_LOADING_SWITCHES_CHART_DATA, SET_SWITCHES_CHART_DATA } from "../actions";
import { SET_IS_LOADING_AP_CHART_DATA } from "../actions/setIsLoadingAPChartData";


const initialState = {
    stores: [],
    isLoadingChartPage: false,
    top10AccessPointsData: [],
    top10Switches: [],
    isLoadingAPChartData: false,
    accessPointsChartData: [],
    isLoadingTop10Switches: false,
    isLoadingTop10APData: false,
    isLoadingSwitchesChartData: false,
    switchesChartData: []
}

const chartsReducer = (state = initialState, action) => {

    if (action.type === SET_IS_LOADING_CHART_PAGE) {
        return {
            ...state,
            isLoadingChartPage: action.isloadingChartPage
        }
    }

    if (action.type === SET_STORES) {
        let stores = action.stores;

        return {
            ...state,
            stores: stores,
            isLoadingChartPage: false
        }
    }

    if (action.type === SET_IS_LOADING_AP_CHART_DATA) {
        return {
            ...state,
            isLoadingAPChartData: action.isLoadingAPChartData
        }
    }

    if (action.type === SET_IS_LOADING_SWITCHES_CHART_DATA) {
        return {
            ...state,
            isLoadingSwitchesChartData: action.isLoadingSwitchesChartData
        }
    }

    if (action.type === SET_SWITCHES_CHART_DATA) {
        return {
            ...state,
            switchesChartData: action.switchesChartData,
            isLoadingSwitchesChartData: false
        }
    }

    if (action.type === SET_IS_LOADING_TOP_10_AP_DATA) {
        return {
            ...state,
            isLoadingTop10APData: action.isLoadingTop10APData
        }
    }

    if (action.type === SET_IS_LOADING_TOP_10_SWITCHES) {
        return {
            ...state,
            isLoadingTop10Switches: action.isLoadingTop10Switches
        }
    }

    if (action.type === SET_ACCESS_POINTS_CHART_DATA) {
        return {
            ...state,
            accessPointsChartData: action.accessPointsChartData,
            isLoadingAPChartData: false
        }
    }

    if (action.type === SET_TOP_10_ACCESS_POINTS) {
        return {
            ...state,
            top10AccessPointsData: action.top10AccessPoints,
            isLoadingTop10APData: false
        }
    }

    if (action.type === SET_TOP_10_SWITCHES) {
        return {
            ...state,
            top10Switches: action.top10Switches,
            isLoadingTop10Switches: false
        }
    }


    return state;
}


export default chartsReducer;