
import { postAsync, getAsync, putAsync } from "./apiBase";
import { setDevicesOverview, setIsSaving, setIsLoadingSettings, setIsAdminUser } from "../actions";
import store from '../store';

const saveAuthToken = async (arubaAuthToken) => {
    const authToken = await postAsync('settings/savearubaauthtoken', arubaAuthToken);
}

const getRefreshToken = async () => {
    await getAsync('settings/GetRefreshToken');
}

const getDevicesOverview = async () => {
    store.dispatch(setIsLoadingSettings(true));
    const devicesOverview = await getAsync('settings/GetDevicesOverview');
    store.dispatch(setDevicesOverview(devicesOverview));
    return devicesOverview;
}

const saveDevicesOverview = async (devicesOverviewModel) => {
    store.dispatch(setIsSaving(true));
    const devicesOverview = await putAsync('settings/UpdateDevicesOverview', devicesOverviewModel);
    store.dispatch(setIsSaving(false));
    return devicesOverview;
}

const hasAdminUser = async (username) => {
    const adminUserResponse = await getAsync('settings/HasAdminUser/' + username);
    store.dispatch(setIsAdminUser(adminUserResponse.isAdminUser))
    return adminUserResponse;
}

export default { saveAuthToken, getRefreshToken, getDevicesOverview, saveDevicesOverview, hasAdminUser };