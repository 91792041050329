import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import store from '../../store';
import NetworkCategoryList from './NetworkCategoryList';
import regionalFulfilmentCentreApi from '../../api/regionalFulfilmentCentreApi';
import './Settings.css';

const RegionalFulfilmentCentreList = (props) => {

    const { isLoadingRegionalFulfilmentCentres, regionalFulfilmentCentres } = useSelector((state) => state.regionalFulfilmentCentreReducer);

    useEffect(() => {
        regionalFulfilmentCentreApi.getRegionalFulfilmentCentres();
    }, []);

    const onAddRegionalFulfilmentCentreItem = (categoryItem) => {
        regionalFulfilmentCentreApi.addRegionalFulfilmentCentre(categoryItem);
    }

    const onEditRegionalFulfilmentCentreItem = (categoryItem) => {
        regionalFulfilmentCentreApi.updateRegionalFulfilmentCentre(categoryItem);
    }

    const onDeleteRegionalFulfilmentCentreItem = (categoryItemId) => {
        regionalFulfilmentCentreApi.deleteRegionalFulfilmentCentre(categoryItemId);
    }

    const onSearchRFCList = (searchKey) => {
    }

    return (
        <NetworkCategoryList isLoadingItems={isLoadingRegionalFulfilmentCentres}
            categoryList={regionalFulfilmentCentres}
            categoryName='Regional Fulfilment Centre'
            showSearchBox={false}
            onAddCategoryItem={onAddRegionalFulfilmentCentreItem}
            onEditCategoryItem={onEditRegionalFulfilmentCentreItem}
            onDeleteCategoryItem={onDeleteRegionalFulfilmentCentreItem}
            onSearchCategoryList={onSearchRFCList} />
    );
}

export default RegionalFulfilmentCentreList;