import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';

import './PageLoader.css';

const PageLoader = () => {
    return (
        <div className='page-loader'>
            <div className='page-loader-container'>
                <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                /><span style={{ marginLeft: 12 }}> Fetching latest data...</span>
            </div>
        </div>
    );
}

export default PageLoader;
