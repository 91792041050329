export const apiBaseUrl = 'https://iwatch-nprd.azurewebsites.net'; //'https://iwatch-nprd.azurewebsites.net';   

export const ARUBA_API_AUTHTOKEN = 'ARUBA_API_AUTHTOKEN';

export const DeviceStatus = {
    Up: 'Up',
    Down: 'Down'
}

export const DeviceType = {
    AccessPoint: 'accesspoint',
    Switch: 'switch',
    CoreSwitch: 'coreswitch',
    PfsSwitch: 'pfsswitch',
    Controllers: 'controllers',
    Unknown: 'unknown'
}

export const LocationType = {
    All: 'all',
    Stores: 'stores',
    Depots: 'depots',
    SSC: 'ssc'
}

export const arubaConfiguration = {
    clientID: 'G76NPcryzEUv7DMcbbsmIExO8tg7k35B',
    clientSecret: 'UWQaEk80bJ1Pc31K7pJ5Go5B4u3hJjUN',
    grantType: 'refresh_token'
    // refreshToken: 'h2VLwzn7ewDK57r6eBEyHst204OvHWS1',
    // accessToken: ''
}

export const timeIntervalType = {
    thisWeek: 1,
    thisMonth: 2,
    last3Months: 3,
    custom: 4
}

export const arubaRefreshTokenInterval = 6600000; //6900 
export const refreshDataInterval = 900000; //2000;//900000; //6900 
export const networkDataInterval = 1200000;