import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import './SitesModal.css';
import { Row, Col, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';


const CreateOrUpdateCategoryModal = (props) => {
    const { show, handleClose, categoryItem, categoryName, onAddCategoryItem, onEditCategoryItem } = props;

    const { isSavingCategory } = useSelector((state) => state.settingsReducer);


    const [validated, setValidated] = useState(false);
    const [coreSwitchName, setCoreSwitchName] = useState();
    const [siteName, setSiteName] = useState();
    const [ipAddress, setIpAddress] = useState();
    const [source, setSource] = useState();

    useEffect(() => {
        setCoreSwitchName(categoryItem.coreSwitchName);
        setSiteName(categoryItem.siteName);
        setIpAddress(categoryItem.ipAddress);
        setSource(categoryItem.source);
    }, [categoryItem])

    const onCancelButtonClick = () => {
        if (validated) {
            setValidated(false);
        }
        handleClose();
    }

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        setValidated(true);
        if (form.checkValidity() === false) {
            return;
        } else {
            let category = categoryItem;
            category.coreSwitchName = coreSwitchName;
            category.siteName = siteName;
            category.ipAddress = ipAddress;
            category.source = parseInt(source);
            if (categoryItem.id) {
                onEditCategoryItem(category);
            }
            else {
                onAddCategoryItem(category);
            }
            setValidated(false);
            handleClose();
        }
    }

    return (
        <Modal show={show} onHide={handleClose} size='md'>
            <Modal.Header closeButton>
                <Modal.Title style={{ color: '#F06C00', fontSize: 20 }}> {categoryItem.id ? 'Edit' : 'Add'} {categoryName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id="networkCategoryForm" noValidate validated={validated} onSubmit={handleSubmit}>
                    <div className="categorySection">
                        <Row>
                            <Col>
                                <Form.Group controlId="networkCategoryForm.ControlInput1">
                                    <Form.Label>Core Switch Name</Form.Label>
                                    <Form.Control type="text" required defaultValue={categoryItem.coreSwitchName} value={coreSwitchName} onChange={(e) => setCoreSwitchName(e.target.value)} />
                                    <Form.Control.Feedback type="invalid">Please enter the core switch name</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="networkCategoryForm.ControlInput2">
                                    <Form.Label>Site Name</Form.Label>
                                    <Form.Control type="text" required defaultValue={categoryItem.siteName} value={siteName} onChange={(e) => setSiteName(e.target.value)} />
                                    <Form.Control.Feedback type="invalid">Please enter site name</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="networkCategoryForm.ControlInput3">
                                    <Form.Label>Ip Address</Form.Label>
                                    <Form.Control type="text" required={false} defaultValue={categoryItem.ipAddress} value={ipAddress} onChange={(e) => setIpAddress(e.target.value)} />
                                    <Form.Control.Feedback type="invalid">Please enter the ip address</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="networkCategoryForm.ControlInput4">
                                    <Form.Label>Network Source</Form.Label>
                                    <Form.Select defaultValue={categoryItem.source} value={source} onChange={(e) => setSource(e.target.value)}>
                                        <option value="1">Aruba Central</option>
                                        <option value="2">Zabbix</option>
                                        <option value="3">Spectrum</option>
                                        <option value="4">Airwave</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">Please enter network source</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </Modal.Body>
            <Modal.Footer >
                <Button type='submit' className='primary-button' form="networkCategoryForm">{isSavingCategory ? 'Saving...' : 'Save'}</Button>{' '}
                <Button variant="outline-dark" onClick={() => onCancelButtonClick()} style={{ borderRadius: 0, marginLeft: 15 }}>Cancel</Button>{' '}
            </Modal.Footer>
        </Modal >
    );
}

export default CreateOrUpdateCategoryModal;