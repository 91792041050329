import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import './DashboardPage.css';
import Logo from '../../Components/Logo';
import storesApi from '../../api/storesApi';
import schedulerApi from '../../api/schedulerApi';
import { setIsLoadingPage } from '../../actions';
import { arubaRefreshTokenInterval, refreshDataInterval, arubaConfiguration, ARUBA_API_AUTHTOKEN } from '../../utils/constants';
import StoresSummary from '../../Components/Stores/StoresSummary';
import DepotsSummary from '../../Components/Depots/DepotsSummary';
import PageLoader from '../../Components/Loaders/PageLoader';
import SupportCenterSummary from '../../Components/SupportCenters/SupportCenterSummary';
import BackgroundPanel from '../../Components/Dashboard/BackgroundPanel';
import OverAllDevicesSummary from '../../Components/Dashboard/OverAllDevicesSummary';
import GolSummary from '../../Components/Stores/GolSummary';
import PFSSummary from '../../Components/Stores/PFSSummary';
import settingsApi from '../../api/settingsApi';
import mobilityControllerApi from '../../api/mobilityControllerApi';

const DashboardPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isLoading } = useSelector((state) => state.storesReducer);
    const { arubaApiAuthentication } = useSelector((state) => state.settingsReducer);
    
    useEffect(() => {
        getData();
        const refreshDataTimer = setInterval(() => getData(), refreshDataInterval);

        return () => {
            clearInterval(refreshDataTimer);
        }
    }, []);

    const getData = async () => {
        dispatch(setIsLoadingPage(true));
        let devicesOverview = await settingsApi.getDevicesOverview();
        await storesApi.getAccessPointsData('accessToken', devicesOverview);
        await storesApi.getSwitchesData('accessToken', devicesOverview);
        await mobilityControllerApi.getMobilityControllerStatus();
        dispatch(setIsLoadingPage(false));
    }

    const getDashboardData = async () => {
        dispatch(setIsLoadingPage(true));
        await storesApi.getAccessPoints();
        await storesApi.getSwitches();
        dispatch(setIsLoadingPage(false));
    }

    const reloadPage = async () => {
        await getData();
    }

    return (
        <div className='dashboard-container'>
            <BackgroundPanel />
            <div className='dashboard-row-container'>
                <Logo onClick={() => reloadPage()} />
                <OverAllDevicesSummary />
                <StoresSummary />
                <GolSummary />
                <DepotsSummary />
                <SupportCenterSummary />
            </div>
            {
                isLoading &&
                <div className='dashboard-row-container'>
                    <PageLoader />
                </div>
            }
        </div>
    );
}

export default DashboardPage;