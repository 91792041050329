import {
    SET_GOL_STORES_ACCESS_POINTS,
    SET_GOL_STORE_SWITCHES
} from '../actions';
import { groupByStores, getCoreSwitches, getPSFSwicthes } from '../utils/utilities';

const groupByName = 'site';
const initialState = {
    isLoading: false,
    golStores: {
        apOnline: 0,
        apOffline: 0,
        assignedApOffline: 0,
        failedApsGroupByStores: [],
        assignedFailedApsGroupByStores: [],
        switchesOnline: 0,
        switchesOffline: 0,
        failedSwitchesGroupByStores: [],
        coreSwitchesOnline: 0,
        coreSwitchesOffline: 0,
        failedCoreSwitchesGroupByStores: [],
        pfsSwitchesOnline: 0,
        pfsSwitchesOffline: 0,
        failedPfsSwitchesGroupByStores: []
    },
    accessPoints: [],
    switches: []
}

const golStoresReducer = (state = initialState, action) => {
    if (action.type === SET_GOL_STORES_ACCESS_POINTS) {
        let apsData = action.golStoreAccessPoints;
        let failedAps = apsData.failedStoresAccessPoints;
        let assignedAps = failedAps.filter(s => Boolean(s.notes));
        let offlineAps = failedAps.filter(s => !Boolean(s.notes));
        offlineAps = offlineAps.filter(f => f.site && !f.site.includes('Pre Staging not in Live'));
        return {
            ...state,
            golStores: {
                ...state.golStores,
                apOnline: apsData.onlineCount,
                apOffline: offlineAps.length,
                assignedApOffline: assignedAps.length,
                assignedFailedApsGroupByStores: groupByStores(assignedAps, groupByName),
                failedApsGroupByStores: groupByStores(offlineAps, groupByName)
            }
        }
    }

    if (action.type === SET_GOL_STORE_SWITCHES) {
        let switchesData = action.golStoreSwitches;
        let failedGolStoresSwitches = switchesData.failedGolStoresSwitches;
        let coreSwitches = getCoreSwitches(failedGolStoresSwitches);
        let pfsSwitches = getPSFSwicthes(failedGolStoresSwitches);
        return {
            ...state,
            golStores: {
                ...state.golStores,
                switchesOnline: switchesData.onlineCount,
                switchesOffline: failedGolStoresSwitches.length,
                failedSwitchesGroupByStores: groupByStores(failedGolStoresSwitches, groupByName),
                coreSwitchesOnline: (switchesData.golStoresCoreSwicthesTotal - coreSwitches.length),
                coreSwitchesOffline: coreSwitches.length,
                failedCoreSwitchesGroupByStores: groupByStores(coreSwitches, groupByName),
                pfsSwitchesOnline: (switchesData.golStoresPfsSwicthesTotal - pfsSwitches.length),
                pfsSwitchesOffline: pfsSwitches.length,
                failedPfsSwitchesGroupByStores: groupByStores(pfsSwitches, groupByName),
            }
        }
    }
    return state;
}

export default golStoresReducer;