import { SET_IS_LOADING_REGIONAL_FULFILMENT_CENTRES, SET_REGIONAL_FULFILMENT_CENTRES } from "../actions";


const initialState = {
    isLoadingRegionalFulfilmentCentres: false,
    regionalFulfilmentCentreListSource: [],
    regionalFulfilmentCentres: []
}

const networkDepotReducer = (state = initialState, action) => {
    if (action.type === SET_IS_LOADING_REGIONAL_FULFILMENT_CENTRES) {
        return {
            ...state,
            isLoadingRegionalFulfilmentCentres: action.isLoadingRegionalFulfilmentCentres
        }
    }

    if (action.type === SET_REGIONAL_FULFILMENT_CENTRES) {
        return {
            ...state,
            regionalFulfilmentCentres: action.regionalFulfilmentCentres,
            regionalFulfilmentCentreListSource: action.regionalFulfilmentCentres,
            isLoadingRegionalFulfilmentCentres: false
        }
    }

    return state;
}

export default networkDepotReducer;