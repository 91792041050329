import React from 'react';

import './Card.css';

const DefaultCountCard = () => {
    return (
        <div className='count-card default-count'>0</div>
    );
}

export default DefaultCountCard;