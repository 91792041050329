import React, { useState, useEffect } from 'react';

import './Card.css';
import NetworkHardDownSitesModal from '../Modals/NetworkHardDownSitesModal';
import NetworkUpSitesModal from '../Modals/NetworkUpSitesModal';

const LargeStatusCard = (props) => {
    const { onlineCount, offlineCount, offlineSites, onlineSites } = props;
    const [showOfflineSites, setShowOfflineSites] = useState(false);
    const [showOnlineSites, setShowOnlineSites] = useState(false);
    
    return (
        <div className='status-container'>
            {
                onlineSites.length > 0 ? <div className='status-card online-status' style={{ cursor: "pointer" }} onClick={() => setShowOnlineSites(true)}>{onlineCount}</div>
                    : <div className='status-card default-status'>{onlineCount}</div>
            }
            {
                offlineSites.length > 0 ? <div className='status-card offline-status' style={{ cursor: "pointer" }} onClick={() => setShowOfflineSites(true)}><div className="animate-count">{offlineCount}</div></div>
                    : <div className='status-card default-status'>{offlineCount}</div>
            }
            {
                showOnlineSites && <NetworkUpSitesModal show={showOnlineSites} handleClose={() => setShowOnlineSites(false)} onlineSites={onlineSites} />
            }
            {
                <NetworkHardDownSitesModal show={showOfflineSites} handleClose={() => setShowOfflineSites(false)} offlineSites={offlineSites} />
            }
        </div>
    );
}

export default LargeStatusCard;