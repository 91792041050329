
import { postAsync, getAsync, putAsync, deleteAsync } from "./apiBase";
import store from '../store';
import { setStandAloneArgosList, setIsLoadingStandAloneArgosList, setIsDeletingCategory, setIsSavingCategory, setCategoryApiResponse } from "../actions";

const getStandAloneArgosList = async () => {
    store.dispatch(setIsLoadingStandAloneArgosList(true));
    const standaloneargoslist = await getAsync('standaloneargos/getstandaloneargoslist');
    store.dispatch(setStandAloneArgosList(standaloneargoslist))
}

const addStandAloneArgos = async (standAloneArgos) => {
    store.dispatch(setIsSavingCategory(true));
    var response = await postAsync('standaloneargos/addstandaloneargos', standAloneArgos);
    store.dispatch(setCategoryApiResponse(response.data));
    store.dispatch(setIsSavingCategory(false));
    await getStandAloneArgosList();
}

const updateStandAloneArgos = async (standAloneArgos) => {
    store.dispatch(setIsSavingCategory(true));
    var response = await putAsync('standaloneargos/updatestandaloneargos', standAloneArgos);
    store.dispatch(setCategoryApiResponse(response.data));
    store.dispatch(setIsSavingCategory(false));
    await getStandAloneArgosList();
}

const deleteStandAloneArgos = async (standAloneArgosId) => {
    store.dispatch(setIsDeletingCategory(true));
    var response = await deleteAsync('standaloneargos/deletestandaloneargos/' + standAloneArgosId);
    store.dispatch(setCategoryApiResponse(response.data));
    store.dispatch(setIsDeletingCategory(false));
    await getStandAloneArgosList();
}

export default { getStandAloneArgosList, addStandAloneArgos, updateStandAloneArgos, deleteStandAloneArgos };