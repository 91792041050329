import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Row, Col, Spinner } from 'react-bootstrap';
import settingsApi from '../../api/settingsApi';
import { PencilFill, Trash3Fill } from 'react-bootstrap-icons';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import './Settings.css';

const DashboardSettingsForm = (props) => {


    const { isSaving, isLoading, devicesOverview } = useSelector((state) => state.settingsReducer);
    const navigate = useNavigate();
    const [validated, setValidated] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [storesApsTotal, setStoresApsTotal] = useState();
    const [golStoresApsTotal, setGolStoresApsTotal] = useState();
    const [depotApsTotal, setDepotApsTotal] = useState();
    const [sscApsTotal, setSSCApsTotal] = useState();

    const [storesSwitchesTotal, setStoresSwitchesTotal] = useState();
    const [golStoresSwicthesTotal, setGolStoresSwicthesTotal] = useState();
    const [depotSwicthesTotal, setDepotSwicthesTotal] = useState();
    const [sscSwicthesTotal, setSSCSwicthesTotal] = useState();

    const [storesCoreSwicthesTotal, setStoresCoreSwicthesTotal] = useState();
    const [golStoresCoreSwicthesTotal, setGolStoresCoreSwicthesTotal] = useState();
    const [depotCoreSwicthesTotal, setDepotCoreSwicthesTotal] = useState();
    const [sscCoreSwicthesTotal, setSSCCoreSwicthesTotal] = useState();

    const [storesPfsSwicthesTotal, setStoresPfsSwicthesTotal] = useState();
    const [golStoresPfsSwicthesTotal, setGolStoresPfsSwicthesTotal] = useState();
    const [depotPfsSwicthesTotal, setDepotPfsSwicthesTotal] = useState();
    const [sscPfsSwicthesTotal, setSSCPfsSwicthesTotal] = useState();

    useEffect(() => {
        if (props.tabKey === 'device-overview') {
            setIsEdit(false);
            getDashboardSetting();
        }
    }, [props.tabKey]);

    useEffect(() => {

    }, []);

    const getDashboardSetting = async () => {
        await settingsApi.getDevicesOverview();
        if (devicesOverview) {
            setStoresApsTotal(devicesOverview.storesApsTotal);
            setGolStoresApsTotal(devicesOverview.golStoresApsTotal);
            setDepotApsTotal(devicesOverview.depotApsTotal);
            setSSCApsTotal(devicesOverview.sscApsTotal);
            setStoresSwitchesTotal(devicesOverview.storesSwitchesTotal);
            setGolStoresSwicthesTotal(devicesOverview.golStoresSwicthesTotal);
            setDepotSwicthesTotal(devicesOverview.depotSwicthesTotal);
            setSSCSwicthesTotal(devicesOverview.sscSwicthesTotal);
            setStoresCoreSwicthesTotal(devicesOverview.storesCoreSwicthesTotal);
            setGolStoresCoreSwicthesTotal(devicesOverview.golStoresCoreSwicthesTotal);
            setDepotCoreSwicthesTotal(devicesOverview.depotCoreSwicthesTotal);
            setSSCCoreSwicthesTotal(devicesOverview.sscCoreSwicthesTotal);
            setStoresPfsSwicthesTotal(devicesOverview.storesPfsSwicthesTotal);
            setDepotPfsSwicthesTotal(devicesOverview.depotPfsSwicthesTotal);
            setGolStoresPfsSwicthesTotal(devicesOverview.golStoresPfsSwicthesTotal);
            setSSCPfsSwicthesTotal(devicesOverview.sscPfsSwicthesTotal);
        }
    }

    const handleSubmit = async (event) => {
        const form = event.currentTarget;

        event.preventDefault();
        setValidated(true);
        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            if (devicesOverview) {
                let updatedData = {
                    ...devicesOverview,
                    storesApsTotal,
                    golStoresApsTotal,
                    depotApsTotal,
                    sscApsTotal,
                    storesSwitchesTotal,
                    golStoresSwicthesTotal,
                    depotSwicthesTotal,
                    sscSwicthesTotal,
                    storesCoreSwicthesTotal,
                    golStoresCoreSwicthesTotal,
                    depotCoreSwicthesTotal,
                    sscCoreSwicthesTotal,
                    storesPfsSwicthesTotal,
                    golStoresPfsSwicthesTotal,
                    depotPfsSwicthesTotal,
                    sscPfsSwicthesTotal
                }
                await settingsApi.saveDevicesOverview(updatedData);
                navigate('/');
            }
        }
    };

    const onClear = () => {
        setStoresApsTotal('');
        setGolStoresApsTotal('');
        setDepotApsTotal('');
        setSSCApsTotal('');
        setStoresSwitchesTotal('');
        setGolStoresSwicthesTotal('');
        setDepotSwicthesTotal('');
        setSSCSwicthesTotal('');
        setStoresCoreSwicthesTotal('');
        setGolStoresCoreSwicthesTotal('');
        setDepotCoreSwicthesTotal('');
        setSSCCoreSwicthesTotal('');
        setStoresPfsSwicthesTotal('');
        setDepotPfsSwicthesTotal('');
        setGolStoresPfsSwicthesTotal('')
        setSSCPfsSwicthesTotal('')
        setIsEdit(true);
    }

    const renderEditTooltip = (props) => {
        return (
            <Tooltip id="button-tooltip" {...props}>
                Edit settings
            </Tooltip>
        );
    }

    const renderClearTooltip = (props) => {
        return (
            <Tooltip id="button-tooltip" {...props}>
                Clear settings
            </Tooltip>
        );
    }

    return (
        <div className='settingSection'>
            <div className='settings-header'>
                <h4>Dashboard Settings </h4>
                {
                    !isEdit && <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderEditTooltip}>
                        <span className='edit-icon' onClick={() => setIsEdit(true)}><PencilFill color='#f06c00' /></span>
                    </OverlayTrigger>
                }

                {/* <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderClearTooltip}>
                    <span className='edit-icon' onClick={() => onClear()}><Trash3Fill color='gray' /></span>
                </OverlayTrigger> */}
            </div>
            {
                isLoading
                    ? <div className='loader-container'><Spinner /></div>
                    : <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <h6>Access Points</h6>
                        <div className="dashboardSection">
                            <Row>
                                <Col>
                                    <Form.Group controlId="dashboardForm.ControlInput1">
                                        <Form.Label>Stores Total</Form.Label>
                                        <Form.Control type="number" defaultValue={devicesOverview.storesApsTotal} value={storesApsTotal} disabled={!isEdit} required onChange={(e) => setStoresApsTotal(e.target.value)} />
                                        <Form.Control.Feedback type="invalid">Please enter stores total</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="dashboardForm.ControlInput2">
                                        <Form.Label>Gol Stores Total</Form.Label>
                                        <Form.Control type="number" defaultValue={devicesOverview.golStoresApsTotal} value={golStoresApsTotal} disabled={!isEdit} required onChange={(e) => setGolStoresApsTotal(e.target.value)} />
                                        <Form.Control.Feedback type="invalid">Please enter gol stores total</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="dashboardForm.ControlInput3">
                                        <Form.Label>Depots Total</Form.Label>
                                        <Form.Control type="number" defaultValue={devicesOverview.depotApsTotal} value={depotApsTotal} disabled={!isEdit} required onChange={(e) => setDepotApsTotal(e.target.value)} />
                                        <Form.Control.Feedback type="invalid">Please enter depots total</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="dashboardForm.ControlInput4">
                                        <Form.Label>SSC Total</Form.Label>
                                        <Form.Control type="number" defaultValue={devicesOverview.sscApsTotal} value={sscApsTotal} disabled={!isEdit} required onChange={(e) => setSSCApsTotal(e.target.value)} />
                                        <Form.Control.Feedback type="invalid">Please enter ssc total</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </div>
                        <h6>Switches</h6>
                        <div className="dashboardSection">
                            <Row>
                                <Col>
                                    <Form.Group controlId="dashboardForm.ControlInput5">
                                        <Form.Label>Stores Total</Form.Label>
                                        <Form.Control type="number" value={storesSwitchesTotal} defaultValue={devicesOverview.storesSwitchesTotal} disabled={!isEdit} required onChange={(e) => setStoresSwitchesTotal(e.target.value)} />
                                        <Form.Control.Feedback type="invalid">Please enter stores total</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="dashboardForm.ControlInput6">
                                        <Form.Label>Gol Stores Total</Form.Label>
                                        <Form.Control type="number" value={golStoresSwicthesTotal} defaultValue={devicesOverview.golStoresSwicthesTotal} disabled={!isEdit} required onChange={(e) => setGolStoresSwicthesTotal(e.target.value)} />
                                        <Form.Control.Feedback type="invalid">Please enter gol stores total</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="dashboardForm.ControlInput7">
                                        <Form.Label>Depots Total</Form.Label>
                                        <Form.Control type="number" value={depotSwicthesTotal} defaultValue={devicesOverview.depotSwicthesTotal} disabled={!isEdit} required onChange={(e) => setDepotSwicthesTotal(e.target.value)} />
                                        <Form.Control.Feedback type="invalid">Please enter depots total</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="dashboardForm.ControlInput8">
                                        <Form.Label>SSC Total</Form.Label>
                                        <Form.Control type="number" value={sscSwicthesTotal} defaultValue={devicesOverview.sscSwicthesTotal} disabled={!isEdit} required onChange={(e) => setSSCSwicthesTotal(e.target.value)} />
                                        <Form.Control.Feedback type="invalid">Please enter ssc total</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </div>
                        <h6>Core Switches</h6>
                        <div className="dashboardSection">
                            <Row>
                                <Col>
                                    <Form.Group controlId="dashboardForm.ControlInput9">
                                        <Form.Label>Stores Total</Form.Label>
                                        <Form.Control type="number" value={storesCoreSwicthesTotal} defaultValue={devicesOverview.storesCoreSwicthesTotal} disabled={!isEdit} required onChange={(e) => setStoresCoreSwicthesTotal(e.target.value)} />
                                        <Form.Control.Feedback type="invalid">Please enter stores total</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="dashboardForm.ControlInput10">
                                        <Form.Label>Gol Stores Total</Form.Label>
                                        <Form.Control type="number" value={golStoresCoreSwicthesTotal} defaultValue={devicesOverview.golStoresCoreSwicthesTotal} disabled={!isEdit} required onChange={(e) => setGolStoresCoreSwicthesTotal(e.target.value)} />
                                        <Form.Control.Feedback type="invalid">Please enter gol stores total</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="dashboardForm.ControlInput11">
                                        <Form.Label>Depots Total</Form.Label>
                                        <Form.Control type="number" value={depotCoreSwicthesTotal} defaultValue={devicesOverview.depotCoreSwicthesTotal} disabled={!isEdit} required onChange={(e) => setDepotCoreSwicthesTotal(e.target.value)} />
                                        <Form.Control.Feedback type="invalid">Please enter depots total</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="dashboardForm.ControlInput12">
                                        <Form.Label>SSC Total</Form.Label>
                                        <Form.Control type="number" value={sscCoreSwicthesTotal} defaultValue={devicesOverview.sscCoreSwicthesTotal} disabled={!isEdit} required onChange={(e) => setSSCCoreSwicthesTotal(e.target.value)} />
                                        <Form.Control.Feedback type="invalid">Please enter ssc total</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </div>
                        <h6>PFS Switches</h6>
                        <div className="dashboardSection">
                            <Row>
                                <Col>
                                    <Form.Group controlId="dashboardForm.ControlInput13">
                                        <Form.Label>Stores Total</Form.Label>
                                        <Form.Control type="number" value={storesPfsSwicthesTotal} defaultValue={devicesOverview.storesPfsSwicthesTotal} disabled={!isEdit} required onChange={(e) => setStoresPfsSwicthesTotal(e.target.value)} />
                                        <Form.Control.Feedback type="invalid">Please enter stores total</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="dashboardForm.ControlInput14">
                                        <Form.Label>Gol Stores Total</Form.Label>
                                        <Form.Control type="number" value={golStoresPfsSwicthesTotal} defaultValue={devicesOverview.golStoresPfsSwicthesTotal} disabled={!isEdit} required onChange={(e) => setGolStoresPfsSwicthesTotal(e.target.value)} />
                                        <Form.Control.Feedback type="invalid">Please enter gol stores total</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="dashboardForm.ControlInput15">
                                        <Form.Label>Depots Total</Form.Label>
                                        <Form.Control type="number" value={depotPfsSwicthesTotal} defaultValue={devicesOverview.depotPfsSwicthesTotal} disabled={!isEdit} required onChange={(e) => setDepotPfsSwicthesTotal(e.target.value)} />
                                        <Form.Control.Feedback type="invalid">Please enter depots total</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="dashboardForm.ControlInput16">
                                        <Form.Label>SSC Total</Form.Label>
                                        <Form.Control type="number" value={sscPfsSwicthesTotal} defaultValue={devicesOverview.sscPfsSwicthesTotal} disabled={!isEdit} required onChange={(e) => setSSCPfsSwicthesTotal(e.target.value)} />
                                        <Form.Control.Feedback type="invalid">Please enter ssc total</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </div>
                        {
                            isEdit
                                ? <div style={{ marginTop: 15 }}>
                                    <Button type='submit' className='primary-button'>{isSaving ? 'Saving...' : 'Save'}</Button>{' '}
                                    <Button variant="outline-dark" onClick={() => setIsEdit(false)} style={{ marginLeft: 15 }}>Cancel</Button>{' '}
                                    {/* <Button variant="danger" onClick={() => onClear()} style={{ marginLeft: 15 }}>Clear</Button>{' '} */}
                                </div>
                                : null
                        }

                    </Form>
            }
        </div>
    );
}

export default DashboardSettingsForm;