const golStores = [
    "JS0003 Pepper Hill",
    "JS0005 Bath",
    "JS0006 Chichester",
    "JS0007 Eltham",
    "JS0008 Colchester Ave",
    "JS0011 Lee Green",
    "JS0015 East Grinstead",
    "JS0017 Crayford",
    "JS0018 Lords Hill",
    "JS0019 Locksbottom",
    "JS0020 Tunbridge Wells",
    "JS0021 Lewes Road",
    "JS0023 Warlingham",
    "JS0024 Streatham Common",
    "JS0026 West Green",
    "JS0027 Sedlescombe Road",
    "JS0028 Larkfield",
    "JS0031 New Cross Gate",
    "JS0038 North Cheam",
    "JS0051 West Hove",
    "JS0052 Purley Way",
    "JS0053 Blackpole",
    "JS0054 Dulwich",
    "JS0055 Whistable",
    "JS0057 West Park Farm",
    "JS0058 Lyons Farm",
    "JS0059 Bybrook",
    "JS0062 Hampden Park",
    "JS0063 Sevenoaks",
    "JS0069 Wandsworth",
    "JS0072 Rayleigh Weir",
    "JS0116 Haywards Heath",
    "JS0206 Tonbridge",
    "JS0226 Chiswick",
    "JS0229 Cromwell Road",
    "JS0391 Horsham",
    "JS0402 Harlow",
    "JS0403 Thorley",
    "JS0406 Harringay",
    "JS0408 Warren Heath",
    "JS0409 Newbury Park",
    "JS0410 Stevenage",
    "JS0411 Bury St Edmunds",
    "JS0414 Great Yarmouth",
    "JS0415 Springfield",
    "JS0417 Peterborough",
    "JS0419 Haverhill",
    "JS0420 Winchmore Hill",
    "JS0421 Letchworth",
    "JS0422 Hadleigh Road",
    "JS0426 Biggleswade",
    "JS0433 Low Hall",
    "JS0434 Coreys Mill",
    "JS0440 Southend",
    "JS0441 Pound Lane",
    "JS0443 East Mayne",
    "JS0444 Enfield",
    "JS0474 Thetford",
    "JS0503 Leamington",
    "JS0505 Bramingham Park",
    "JS0509 Thornhill",
    "JS0510 Swansea",
    "JS0514 Rugby",
    "JS0516 Tewkesbury Road",
    "JS0519 Hereford",
    "JS0521 Melksham",
    "JS0522 Sittingbourne",
    "JS0523 Drumchapel",
    "JS0524 Garthdee",
    "JS0529 Stirling",
    "JS0536 Strand Road",
    "JS0537 Ballymena",
    "JS0538 Newry",
    "JS0548 Richmond",
    "JS0549 Dundee",
    "JS0555 Washington",
    "JS0556 Hempstead Valley",
    "JS0558 Oldbury",
    "JS0559 Calcot",
    "JS0560 Cameron Toll",
    "JS0566 Merton",
    "JS0567 London Colney",
    "JS0569 Beckton",
    "JS0600 Heyford Hill",
    "JS0601 Dome Roundabout",
    "JS0602 Ladbroke Grove",
    "JS0603 Uxbridge",
    "JS0604 Redditch",
    "JS0608 Barnwood",
    "JS0609 Fairfield Park",
    "JS0610 Wellingborough",
    "JS0611 Dunstable",
    "JS0612 Finchley Road",
    "JS0628 Kettering",
    "JS0629 St Albans",
    "JS0630 Hayes",
    "JS0632 Witney",
    "JS0633 Rhyl",
    "JS0634 Apsley Mills",
    "JS0635 Kenton",
    "JS0637 Hendon",
    "JS0638 Taplow",
    "JS0640 Kidderminster",
    "JS0642 Banbury",
    "JS0646 Alperton",
    "JS0648 Weedon Road",
    "JS0652 Burpham",
    "JS0655 Taunton",
    "JS0656 Ferndown",
    "JS0657 Hedge End",
    "JS0661 St Clares",
    "JS0662 Alton",
    "JS0664 Chippenham",
    "JS0665 Kempshott",
    "JS0666 Badger Farm",
    "JS0668 Torquay",
    "JS0669 Bridgemead",
    "JS0670 Godalming",
    "JS0671 Truro",
    "JS0672 Farlington",
    "JS0673 Bagshot Road",
    "JS0674 Barnstaple",
    "JS0675 Watchmoor Park",
    "JS0676 Talbot Heath",
    "JS0677 Emersons Green",
    "JS0678 Winterstoke Road",
    "JS0679 Staines",
    "JS0680 Water Lane",
    "JS0681 Broadcut",
    "JS0682 Pinhoe Road",
    "JS0683 Kiln Lane",
    "JS0685 Frome",
    "JS0686 Brookwood",
    "JS0687 Cobham",
    "JS0688 Christchurch",
    "JS0690 Newbury",
    "JS0691 Marsh Mills",
    "JS0693 Sydenham",
    "JS0695 White Rose",
    "JS0696 Romford",
    "JS0706 Longwater",
    "JS0709 Hankridge Farm",
    "JS0711 Harrogate",
    "JS0713 Monks Cross",
    "JS0714 Marshall Lake",
    "JS0715 Mere Green",
    "JS0718 Shrewsbury",
    "JS0721 Chester",
    "JS0722 Amblecote",
    "JS0723 Altrincham ",
    "JS0725 Lincoln",
    "JS0727 Fosse Park",
    "JS0732 Edenthorpe",
    "JS0735 Worksop",
    "JS0739 Beaconsfield",
    "JS0742 Ripley",
    "JS0744 Ellesmere Port",
    "JS0745 Stratton",
    "JS0749 Preston",
    "JS0751 Chaddesden",
    "JS0752 Blackhall",
    "JS0753 Swadlincote",
    "JS0757 Kempston",
    "JS0765 East Prescot Road",
    "JS0766 Salisbury",
    "JS0772 Cheadle",
    "JS0773 Canley",
    "JS0774 Sunderland",
    "JS0775 Cannock",
    "JS0776 Durham",
    "JS0778 Halifax",
    "JS0789 Court House Green",
    "JS0790 Middlesbrough",
    "JS0793 Shorehead",
    "JS0795 Castle Court",
    "JS0797 East Filton",
    "JS0801 Paignton",
    "JS0812 Straiton",
    "JS0813 Castle Boulevard",
    "JS0814 Bradford",
    "JS0815 Coldhams Lane",
    "JS0816 Kimberley",
    "JS0820 Worle",
    "JS0823 Perton",
    "JS0825 Hull",
    "JS0830 York",
    "JS0832 Archer Road",
    "JS0847 Keighley",
    "JS0849 Chesterfield",
    "JS0850 Prestwick",
    "JS0851 Warrington",
    "JS0852 Loughborough",
    "JS0856 Lancaster",
    "JS0859 Beeston ",
    "JS0863 Warwick",
    "JS0864 Whitley Bay",
    "JS0867 Leeds Colton",
    "JS0871 Kingsway",
    "JS0882 Newton Abbot",
    "JS0885 Darnley",
    "JS0886 Denton",
    "JS0890 Wrexham",
    "JS0892 Grimsby",
    "JS0893 East Kilbride",
    "JS0894 Bamber Bridge",
    "JS0897 Dewsbury",
    "JS2001 Kirkcaldy",
    "JS2004 Moortown",
    "JS2005 Hoddesdon",
    "JS2006 Wallington",
    "JS2010 Alphington Road",
    "JS2011 Rice Lane",
    "JS2040 Wednesfield",
    "JS2048 Berryden Road",
    "JS2050 Maidstone",
    "JS2051 Hazel Grove",
    "JS2053 Sury Basin",
    "JS2054 Ashton Moss",
    "JS2059 Osmaston Park",
    "JS2061 Sprucefield",
    "JS2067 Didcot",
    "JS2071 Chafford Hundred",
    "JS2077 Scunthorpe",
    "JS2079 Spalding",
    "JS2080 Scarborough",
    "JS2082 Darlington",
    "JS2100 Arnold",
    "JS2105 Isle of Wight",
    "JS2113 Stoke on Trent",
    "JS2114 Holywood Exchange",
    "JS2136 Newhaven Harbour",
    "JS2153 Penrith",
    "JS2154 Murrayfield",
    "JS2155 Welwyn Garden City",
    "JS2162 Helston",
    "JS2168 Stanway",
    "JS2170 Nantwich",
    "JS2173 Maidenhead",
    "JS2175 Milton Keynes",
    "JS2181 West Belfast",
    "JS2197 Trowbridge",
    "JS2199 Bishop Auckland",
    "JS2219 Worcester St Johns",
    "JS2221 Gloucester Quays",
    "JS2242 Slough Uxbridge Road",
    "JS2243 Bicester",
    "JS2246 Newport New",
    "JS2247 Morecambe",
    "JS2248 Irwin",
    "JS2249 Nairn",
    "JS2252 Portishead",
    "JS2254 Livingston",
    "JS2255 Kelso",
    "JS2258 Wakefield Marsh Way",
    "JS2265 Southampton Portswood",
    "JS2268 Heaton",
    "JS2269 Mansfield",
    "JS2271 Ely",
    "JS2274 Pontypridd",
    "JS2281 Leek",
    "JS2283 Penzance",
    "JS2286 Carlisle",
    "JS2288 Heaton Park",
    "JS2289 Waterlooville",
    "JS2290 Bognor Regis",
    "JS2293 Kings Lynn",
    "JS2297 Sunderland North",
    "JS2302 Whitchurch",
    "JS2304 Leigh",
    "JS2306 Tottenham",
    "JS2308 Weymouth",
    "JS2309 Edinburgh Longstone",
    "JS2310 Longbridge",
    "JS2311 Selly Oak",
    "JS2314 Leicester North",
    "JS2315 Rustington",
    "JS2319 Blackpool",
    "JS2320 Hernebay",
    "JS2322 Sheffield Wadsley Bridge",
    "JS2324 Thanet Westwood Cross",
    "JS2325 Redhill",
    "JS2327 Abbey Wood",
    "JS2331 Sutton",
    "JS2336 Kendal",
    "JS2337 South Woodham Ferrer ",
    "JS2340 Wolverhampton St Marks",
    "JS2393 High Wycombe",
    "JS2399 Aylesbury Gatehouse",
    "JS2400 Colwick",
    "JS2625 Charlton Riverside",
    "JS2658 Fulham Wharf",
    "JS2665 Nine Elms",
    "JS2670 Liverpool Great Homer Street",
    "JS2835 Bolton",
    "JS2848 Burnley"
];

export default golStores;