import { SET_IS_LOADING_STAND_ALONE_ARGOS_LIST, SET_STAND_ALONE_ARGOS_LIST, SEARCH_STAND_ALONE_ARGOS_LIST } from "../actions";

const initialState = {
    isLoadingStandAloneArgosList: false,
    standAloneArgosListSource: [],
    standAloneArgosList: []
}

const standAloneArgosReducer = (state = initialState, action) => {
    if (action.type === SET_IS_LOADING_STAND_ALONE_ARGOS_LIST) {
        return {
            ...state,
            isLoadingStandAloneArgosList: action.isLoadingStandAloneArgosList
        }
    }

    if (action.type === SET_STAND_ALONE_ARGOS_LIST) {
        return {
            ...state,
            standAloneArgosList: action.standAloneArgosList,
            standAloneArgosListSource: action.standAloneArgosList,
            isLoadingStandAloneArgosList: false
        }
    }

    if (action.type === SEARCH_STAND_ALONE_ARGOS_LIST) {
        let sasList = state.standAloneArgosListSource.filter(s => s.siteName.toLowerCase().includes(action.searchKey.toLowerCase()));
        return {
            ...state,
            standAloneArgosList: sasList
        }
    }

    return state;
}

export default standAloneArgosReducer;
