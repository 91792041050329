import React from 'react';
import { useSelector } from 'react-redux';


import DeviceStatusCard from '../Dashboard/DeviceStatusCard';
import DashboardRowHeader from '../Dashboard/DashboardRowHeader';
import { DeviceType, LocationType } from '../../utils/constants';

import StoresIcon from '../../assets/icons/Stores.svg';
const StoresSummary = () => {

    const { isLoading, stores } = useSelector((state) => state.storesReducer);
    const storesHeader = { icon: StoresIcon, title: 'Stores' };

    return (
        <div className='row-item'>
            <div className='first-column'>
                <DashboardRowHeader header={storesHeader} />
            </div>

            <DeviceStatusCard isLoading={isLoading} onlineDevicesCount={stores.coreSwitchesOnline} offlineDevicesCount={stores.coreSwitchesOffline || 0} failedDevices={stores.failedCoreSwitchesGroupByStores} deviceType={DeviceType.CoreSwitch} locationType={LocationType.Stores} />
            <DeviceStatusCard isLoading={isLoading} onlineDevicesCount={stores.switchesOnline} offlineDevicesCount={stores.switchesOffline} failedDevices={stores.failedSwitchesGroupByStores} deviceType={DeviceType.Switch} locationType={LocationType.Stores} />
            <DeviceStatusCard isLoading={isLoading} onlineDevicesCount={stores.pfsSwitchesOnline || 0} offlineDevicesCount={stores.pfsSwitchesOffline || 0} failedDevices={stores.failedPfsSwitchesGroupByStores} deviceType={DeviceType.PfsSwitch} locationType={LocationType.Stores} />
            <DeviceStatusCard isLoading={isLoading} onlineDevicesCount={stores.apOnline} offlineDevicesCount={stores.apOffline} assignedOfflineDevicesCount={stores.assignedApOffline} failedDevices={stores.failedApsGroupByStores} assignedFailedDevices={stores.assignedFailedApsGroupByStores} deviceType={DeviceType.AccessPoint} locationType={LocationType.Stores} />
            <DeviceStatusCard isLoading={isLoading} onlineDevicesCount={stores.controllerOnline || 0} offlineDevicesCount={stores.controllerOffline || 0} failedDevices={[]} deviceType={DeviceType.Unknown} locationType={LocationType.Stores} />
            <DeviceStatusCard isLoading={isLoading} onlineDevicesCount={stores.wanOnline || 0} offlineDevicesCount={stores.wanOffline || 0} failedDevices={[]} deviceType={DeviceType.Unknown} locationType={LocationType.Stores} />
        </div>
    );
}

export default StoresSummary;