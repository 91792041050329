import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DatePicker from "react-datepicker";
import moment from 'moment';
import { Typeahead } from 'react-bootstrap-typeahead';
import Form from 'react-bootstrap/Form';

import TimeIntervalCard from '../../Components/Cards/TimeIntervalCard';
import chartsApi from '../../api/chartsApi';

import './ChartsDashboardPage.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { timeIntervalType } from '../../utils/constants';
import BarChartCard from '../../Components/Charts/BarChartCard';
import LineChartCard from '../../Components/Charts/LineChartCard';
import HorizontalBarChartCard from '../../Components/Charts/HorizontalBarChartCard';
import { Spinner } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";

const ChartsDashboardPage = () => {

    const [selectedStore, setSelectedStore] = useState([]);
    const [selectedTimeInterval, setSelectedTimeInterval] = useState(timeIntervalType.thisMonth);
    const [showAll, setShowAll] = useState(true);
    const { stores,
        isLoadingChartPage,
        accessPointsChartData,
        top10AccessPointsData,
        switchesChartData,
        top10Switches,
        isLoadingAPChartData,
        isLoadingSwitchesChartData,
        isLoadingTop10APData,
        isLoadingTop10Switches } = useSelector((state) => state.chartsReducer);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;

    useEffect(() => {
        chartsApi.getStoresFromFailedDevices();
        let intervalType = selectedTimeInterval;
        getChartData(intervalType);
    }, []);

    // useEffect(() => {
    //     if (stores && stores.length > 0) {
    //         let intervalType = selectedTimeInterval;
    //         getChartData(intervalType);
    //     }
    // }, [stores]);

    const getChartData = (intervalType, startDate, endDate) => {
        if (intervalType === timeIntervalType.custom) {
            chartsApi.getTop10AccessPoints(intervalType, startDate, endDate);
            chartsApi.getTop10Switches(intervalType, startDate, endDate);
            chartsApi.getAccessPointsChartData(intervalType, startDate, endDate);
            chartsApi.getSwitchesChartData(intervalType, startDate, endDate);
        } else {
            setDateRange([null, null]);
            chartsApi.getTop10AccessPoints(intervalType);
            chartsApi.getTop10Switches(intervalType);
            chartsApi.getAccessPointsChartData(intervalType);
            chartsApi.getSwitchesChartData(intervalType);
        }
    }

    const onChangeTimeIntervalType = (intervalType) => {
        getChartData(intervalType);
        setSelectedTimeInterval(intervalType);
    }

    const onSelectStore = (stores) => {
        if (stores.length > 0) {
            let selectedStore = stores[0];
            chartsApi.getAccessPointsChartData(selectedTimeInterval, selectedStore.name);
        } else {
            chartsApi.getAccessPointsChartData(selectedTimeInterval);
        }
        setSelectedStore(stores);
    }

    const prepareData = (data) => {
        if (data) {
            return data.map((a) => {
                a.date = moment(new Date(a.date)).format('MMM DD');
                return a;
            });
        }
    }

    if (isLoadingChartPage) {
        return (
            <div className='charts-container'>
                <div className='chart-loader'><Spinner color='#2f4f4f' /></div>
            </div>
        );
    } else {
        return (
            <div className='charts-container'>
                <div className='charts-header'>
                    <div className='time-interval-container'>
                        <TimeIntervalCard name='This Week' value={timeIntervalType.thisWeek} selected={selectedTimeInterval === timeIntervalType.thisWeek} onSelect={(value) => onChangeTimeIntervalType(value)} />
                        <TimeIntervalCard name='This Month' value={timeIntervalType.thisMonth} selected={selectedTimeInterval === timeIntervalType.thisMonth} onSelect={(value) => onChangeTimeIntervalType(value)} />
                        <TimeIntervalCard name='Last 3 Months' value={timeIntervalType.last3Months} selected={selectedTimeInterval === timeIntervalType.last3Months} onSelect={(value) => onChangeTimeIntervalType(value)} />
                        <TimeIntervalCard name='Custom' value={timeIntervalType.custom} selected={selectedTimeInterval === timeIntervalType.custom} onSelect={(value) => setSelectedTimeInterval(value)} />
                        {
                            selectedTimeInterval === timeIntervalType.custom && <div>
                                <DatePicker
                                    placeholderText='start date - end date'
                                    selectsRange={true}
                                    startDate={startDate}
                                    endDate={endDate}
                                    onChange={(update) => {
                                        setDateRange(update);
                                        let [startDate, endDate] = update;
                                        if (startDate && endDate) {
                                            let start = new Date(startDate).toDateString();
                                            let end = new Date(endDate).toDateString()
                                            getChartData(selectedTimeInterval, start, end);
                                        } else {
                                            getChartData(selectedTimeInterval);
                                        }
                                    }}
                                    monthsShown={2}
                                    isClearable={true}
                                    dateFormat="dd MMM yyyy"
                                />
                            </div>
                        }
                    </div>
                    <div className='right-side-options'>
                        {!showAll && <div className='select-by-store'>
                            <Typeahead
                                clearButton
                                id="stores-typeahead"
                                labelKey="name"
                                onChange={onSelectStore}
                                options={[...stores]}
                                placeholder="Select Store"
                                selected={selectedStore}
                                style={{ fontSize: 14 }}
                            />
                        </div>
                        }
                        <Form.Check
                            // style={{ fontFamily: 'MaryAnnRegular' }}
                            type="switch"
                            id="custom-switch"
                            label="All"
                            checked={showAll}
                            onChange={(e) => {
                                setShowAll(e.target.checked);
                            }}
                        />
                    </div>
                </div>
                <div className='charts-view'>
                    <div className='chart-item-container'>
                        <div className='chart-item-title'>Top 10 Offline Access Points</div>
                        <div className='chart-item' style={{ color: '#8884d8' }}>
                            {
                                isLoadingTop10APData ? <div className='spinner-container'><Spinner /></div>
                                    : <HorizontalBarChartCard key='top10Aps' data={top10AccessPointsData} yAxis="displayName" dataKey="count" color="#8884d8" />
                            }
                        </div>
                    </div>
                    <div className='chart-item-container m-r-5'>
                        <div className='chart-item-title'>Top 10 Offline Switches</div>
                        <div className='chart-item' style={{ color: '#82ca9d' }}>
                            {
                                isLoadingTop10Switches ? <div className='spinner-container'><Spinner /></div>
                                    : <HorizontalBarChartCard key='top10Switches' data={top10Switches} yAxis="displayName" dataKey="count" color="#82ca9d" />
                            }
                        </div>
                    </div>
                </div>
                <div className='charts-view'>
                    <div className='chart-item-container'>
                        <div className='chart-item-title'>Overall Offline Access Points By Date</div>
                        <div className='chart-item' style={{ color: '#8884d8' }}>
                            {
                                isLoadingAPChartData ? <div className='spinner-container'><Spinner /></div>
                                    : <LineChartCard data={prepareData(accessPointsChartData)} dataKey="apCount" xAxis="date" color="#8884d8" />
                            }
                        </div>
                    </div>
                    <div className='chart-item-container m-r-5'>
                        <div className='chart-item-title'>Overall Offline Switches By Date</div>
                        <div className='chart-item' style={{ color: '#82ca9d' }}>
                            {
                                isLoadingSwitchesChartData ? <div className='spinner-container'><Spinner /></div>
                                    : <LineChartCard data={prepareData(switchesChartData)} dataKey="switchesCount" xAxis="date" color="#82ca9d" />
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ChartsDashboardPage;