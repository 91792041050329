import React, { useEffect, useState } from 'react';

const WanUtilizationPage = () => {
    return (
        <div style={{ display: 'flex', fontSize: 24, fontWeight: '400', backgroundColor: '#dcdcdc', flex: 1, width: '100%', height: 'Calc(100vh - 60px)', alignItems: 'center', justifyContent: 'center' }}>
            Coming Soon!!!
        </div>
    );
}

export default WanUtilizationPage;